import axios from 'axios';

export interface ISearchData {
    churchId: string;
    monthId: number;
    year: number;
}

export async function searchReports(props: ISearchData) {
    const { churchId, monthId, year } = props;

    const response = await axios.post(
        `${process.env.REACT_APP_APIPATH}/form/search`,
        {
            churchId: churchId,
            monthId: monthId,
            year: year
        }
    )

    return response.data;
}