import axios from "axios";

export interface ISearchData {
    formId: string;
}

export interface IMonthData {
    monthId: number,
    year: number
}

export interface ICreatorData {
    name: "string",
    email: "string",
    role: "string",
    churchId: "string",
    isActive: "boolean"
}

export interface IExpenseData {
    description: string;
    value: number;
    date: string;
    category: string;
}

export interface IReportData {
    _id: "string",
    formId: "string",
    churchId: "string",
    monthData: IMonthData,
    lastBalance: number,
    creatorData: ICreatorData,
    expenses: Array<Array<IExpenseData>>,
    tenths: "number",
    offers: "number",
    totalEntries: "number",
    totalExpenses: "number",
    balance: "number",
    createdAt: "string",
    updatedAt: "string",
    __v: "number"
}

export async function loadMonthReport(props: ISearchData) {
    const { formId } = props;

    const response = await axios.post(
        `${process.env.REACT_APP_APIPATH}/form/month/load`,
        {
            formId: formId
        },
        {
            Headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
        }
    );

    return response.data;
}