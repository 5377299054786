import React, { useState, useMemo } from 'react';

import { listBillingRequests, ISearchData } from '../controllers/payments/listBillingRequests';

import InternalHeader from '../components/InternalHeader';
import Container from '../components/Container';
import Footer from '../components/Footer';

import { ReactComponent as DownloadIcon } from '../assets/img/download-solid.svg';
import { ReactComponent as FileIcon } from '../assets/img/file-solid.svg';
import { ReactComponent as ShareIcon } from '../assets/img/share-solid.svg';
import { ReactComponent as SmallSearchIcon } from '../assets/img/small-search.svg';

function formatPrice(price: number): string {
    return price.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
}

type qrCode = {
    code: string,
    image: string
}

interface IBillingData {
    txid?: string;
    chargeId?: number;
    description: string;
    value: number;
    customerName: string;
    customerEmail: string;
    customerPhone: string;
    url: string;
    billetLink?: string;
    downloadLink?: string;
    qrCode: qrCode;
    barCode?: string;
    active: boolean;
    createdAt: string;
}

const ManagePayments = () => {
    const [billings, setBillings] = useState([]);
    const [monthId, setMonthId] = useState(0);
    const [year, setYear] = useState(2021);
    const [loading, setLoading] = useState(false);
    const [billingType, setBillingType] = useState("");

    const searchData = {} as ISearchData;
    searchData.page = 1;

    // eslint-disable-next-line
    const LoadExistentBillings = useMemo(() => listBillingRequests(searchData).then(billings => { setBillings(billings)}), billings);

    window.addEventListener("load", () => LoadExistentBillings);

    function handleMonthIdChange(event: React.ChangeEvent<HTMLSelectElement>) {
        setMonthId(parseInt(event.target.value));
    }

    function handleYearChange(event: React.ChangeEvent<HTMLSelectElement>) {
        setYear(parseInt(event.target.value));
    }

    function handleBillingTypeChange(event: React.ChangeEvent<HTMLSelectElement>) {
        setBillingType(event.target.value);
    }

    // TODO: Implementar busca no backend / frontend

    return (
        <React.Fragment>
            <InternalHeader/>
            <Container style={{ justifyContent: 'flex-start', backgroundColor: '#F9F9F9' }}>
            <div className='reports-header'>
                <div className="title-container">
                    <h2>Cobranças / Pagamentos</h2>
                </div>
                <div id="search-container">
                    <div className="search-wrapper">
                        <select name="type" id="type" onChange={handleBillingTypeChange}>
                            <option value="pix">Pix</option>
                            <option value="billet">Boleto</option>
                        </select>
                    </div>
                    
                    <div className="search-wrapper">
                        <select name="month" id="month" onChange={handleMonthIdChange}>
                            <option value="0">Janeiro</option>
                            <option value="1">Fevereiro</option>
                            <option value="2">Março</option>
                            <option value="3">Abril</option>
                            <option value="4">Maio</option>
                            <option value="5">Junho</option>
                            <option value="6">Julho</option>
                            <option value="7">Agosto</option>
                            <option value="8">Setembro</option>
                            <option value="9">Outubro</option>
                            <option value="10">Novembro</option>
                            <option value="11">Dezembro</option>
                        </select>
                    </div>

                    <div className="search-wrapper">
                        <select name="year" id="year" onChange={handleYearChange}>
                            <option value="2021">2021</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                        </select>

                        <button id='search-button'>
                            Buscar
                            <SmallSearchIcon className="search-icon"/>
                        </button>
                    </div>
                </div>
            </div>
            <div className="reports-body" id='reports-body'>
                {
                    billings.map((billing: IBillingData) => {
                        var creationDate = new Date(billing.createdAt);
                        var creationDateJSON = creationDate.toJSON();
                        var arrDate = creationDateJSON.split('T');
                        var date = arrDate[0].split('-');

                        var actualDate = new Date();
                        var dateDiff = Math.abs(actualDate.getTime() - creationDate.getTime());

                        if (billing.txid) {
                            if (dateDiff > 7200) {
                                billing.active = false;
                            }

                            return(
                                <div className='report-element billing' data-txid={billing.txid}>
                                    <div className="report-body">
                                        <div className="report-wrapper">
                                            <h3>PIX - {billing.description}</h3>
                                            <span>{billing.customerName}</span>
                                            <div style={{ display: "flex", gap: "10px" }}>
                                                <a href={billing.url} target="_blank" rel="noreferrer">
                                                    <ShareIcon />
                                                </a>
                                            </div>
                                        </div>
                                        <div className='report-wrapper'>
                                            <b>Valor</b>
                                            <span>{formatPrice(billing.value)}</span>
                                        </div>
                                        <div className='report-wrapper'>
                                            <b>Data de Criação</b>
                                            <span>{date[2]} / {date[1]} / {date[0]}</span>
                                        </div>
                                        <div className='report-wrapper'>
                                            <b>Status</b>
                                            <span>{billing.active ? "Ativa" : "Vencida"}</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        } else {
                            if (dateDiff > 172800000) {
                                billing.active = false;
                            }

                            return(
                                <div className='report-element billing' data-txid={billing.chargeId}>
                                    <div className="report-body">
                                        <div className="report-wrapper">
                                            <h3>BOLETO - {billing.description}</h3>
                                            <span>{billing.customerName}</span>
                                            <div style={{ display: "flex", gap: "10px" }}>
                                                <a href={billing.url} target="_blank" rel="noreferrer">
                                                    <ShareIcon />
                                                </a>
                                                <a href={billing.billetLink} target="_blank" rel="noreferrer">
                                                    <FileIcon />
                                                </a>
                                                <a href={billing.downloadLink} target="_blank" rel="noreferrer" download={ "Boleto_SIGT_" + billing.description.toLowerCase().replaceAll(" ", "-") + ".pdf" }>
                                                    <DownloadIcon />
                                                </a>
                                            </div>
                                        </div>
                                        <div className='report-wrapper'>
                                            <b>Valor</b>
                                            <span>{formatPrice(billing.value)}</span>
                                        </div>
                                        <div className='report-wrapper'>
                                            <b>Data de Criação</b>
                                            <span>{date[2]} / {date[1]} / {date[0]}</span>
                                        </div>
                                        <div className='report-wrapper'>
                                            <b>Status</b>
                                            <span>{billing.active ? "Ativa" : "Inativa"}</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    })
                }
            </div>
            </Container>
            <Footer/>
        </React.Fragment>
    )
}

export default ManagePayments;