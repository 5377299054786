import axios from 'axios';

export interface ISearchEntryData {
    churchId: string,
    week: string
}

export async function searchEntries(props: ISearchEntryData) {
    const { churchId, week } = props;

    const response = await axios.post(
        `${process.env.REACT_APP_APIPATH}/entry/search`,
        {
            churchId,
            week
        },
        {
            Headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
        }
    );

    return response.data;
}