import React, { useState } from 'react';

import Header from '../components/Header';
import Container from '../components/Container'
import Footer from '../components/Footer';

import '../style/forgotPassword.scss';

import { IRecoverData, requestPasswordRecovery } from '../controllers/user/requestPasswordRecovery';
import { RenderForgotPasswordEmail } from '../modules/renderForgotPasswordEmail';
import { sendEmail, IEmailData } from '../controllers/misc/sendEmail';

const ForgotPassword = () => {

    const [email, setEmail] = useState('');

    function handleEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
        setEmail(event.target.value);
    }

    const recoverData:IRecoverData = {
        email: email
    }

    function mountRecoverUrl(uid: any, token: any) {
        return `${window.location.protocol}//${window.location.hostname}:${window.location.port}/reset-password?uid=${uid}&t=${token}`
    }

    function requestRecovery() {
        const messageElement = document.querySelector('.message-wrapper span') as HTMLSpanElement;

        const hideErrorMessage = () => { messageElement.style.display = 'none' };
        const showErrorMessage = () => { messageElement.style.display = 'block'};

        requestPasswordRecovery(recoverData)
            .then((response: any) => {
                if (response._original){
                    messageElement.innerHTML = "Campo e-mail não pode estar vazio";
                    showErrorMessage();
                    setTimeout(hideErrorMessage, 3000);
                } else if (response.message === "User not found with given email") {
                    messageElement.innerHTML = "Endereço de e-mail não associado a nenhum cadastro";
                    showErrorMessage();
                    setTimeout(hideErrorMessage, 3000);
                } else {
                    const recoverUrl = mountRecoverUrl(response.userId, response.token);
                    const mailHTML = RenderForgotPasswordEmail(response.userName, recoverUrl);

                    const emailData:IEmailData = {
                        email: email,
                        subject: "[Portal Transparência PIB] Solicitação de alteração de senha",
                        html: mailHTML
                    }

                    sendEmail(emailData)
                        .then(response => {
                            if (response.status === 'success') {
                                messageElement.innerHTML = "Solicitação de recuperação de senha enviada, favor checar seu email para continuar";
                                messageElement.classList.add('success');
                                showErrorMessage();
                                setTimeout(() => { window.location.href = '/' }, 5000)
                            }
                        })
                }
            })
            .catch((error: any) => {
                console.warn(error);
            })

    }

    return (
      <React.Fragment>
        <Header/>
        <Container style={{ backgroundColor: '#F9F9F9' }}>
            <div className='reset-password-wrapper'>
                <div className='reset-password-header'>
                    <h1>Redefinição de Senha</h1>
                    <span>Insira seu e-mail cadastrado e te daremos instruções de como recuperar sua senha</span>
                </div>
                <form autoComplete='off' onSubmit={e => e.preventDefault()}>
                    <div className="email-wrapper">
                        <label htmlFor="email">E-MAIL</label>
                        <input type="email" name="email" id="email" placeholder='usuario@example.com.br' onChange={handleEmailChange}/>
                    </div>

                    <div className="message-wrapper">
                        <span>Erro ao resetar senha</span>
                    </div>

                    <button type="submit" onClick={requestRecovery}>
                        Criar Nova Senha
                    </button>
                </form>
            </div>
        </Container>
        <Footer/>
      </React.Fragment>
    )
}

export default ForgotPassword;