import React from 'react';

import '../style/buttonReports.scss'

const ButtonReports = () => {
    return (
        <a className='button-reports' href='/relatorios'>
            Ver Relatórios
        </a>
    )

}

export default ButtonReports;