import loadingIcon from '../assets/img/loading.svg';

import '../style/loading.scss'

interface ILoadingPanel {
    loading: boolean
}

const LoadingPanel = (props: ILoadingPanel) => {

    var wrapper = document.querySelector('.loading-wrapper')

    if (props.loading) {
        wrapper?.classList.add("show")
    } else {
        wrapper?.classList.remove("show")
    }

    return (
        <div className="loading-wrapper">
            <img src={loadingIcon} alt="loading-icon"/>
        </div>
    )
}

export default LoadingPanel;