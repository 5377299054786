import React, { useState } from "react";
import { IDate, IExpenseData, createExpense } from '../controllers/expenses/createExpense';

import InternalHeader from '../components/InternalHeader';
import Container from '../components/Container';
import Footer from '../components/Footer';
import { validateUserAgent } from '../controllers/misc/validateUserAgent';

import '../style/createNewUser.scss';

const CreateExpense = () => {
    const actualDate = new Date().toJSON();
    const parsedDate = actualDate.split('T');

    function getWeek(date: Date) {
        var date = new Date(date.getTime());
        date.setHours(0, 0, 0, 0);
        date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
        var week1 = new Date(date.getFullYear(), 0, 4);
        return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
    }

    const [churchId, setChurchId] = useState("sede");
    const [description, setDescription] = useState("");
    const [value, setValue] = useState(0);
    const [date, setDate] = useState(parsedDate[0]);
    const [week, setWeek] = useState(`${new Date().getFullYear()}-W${getWeek(new Date())}`)
    const [category, setCategory] = useState("");

    function handleChurchIdChange(event: React.ChangeEvent<HTMLSelectElement>) {
        setChurchId(event.target.value)
    }

    function handleDescriptionChange(event: React.ChangeEvent<HTMLInputElement>) {
        setDescription(event.target.value)
    }

    function handleValueChange(event: React.ChangeEvent<HTMLInputElement>) {
        setValue(parseFloat(event.target.value))
    }

    function handleDateChange(event: React.ChangeEvent<HTMLInputElement>) {
        setDate(event.target.value)
    }

    function handleWeekChange(event: React.ChangeEvent<HTMLInputElement>) {
        setWeek(event.target.value)
    }

    function handleWeekChange2(event: React.ChangeEvent<HTMLSelectElement>) {
        setWeek(event.target.value)
    }
    
    function handleCategoryChange(event: React.ChangeEvent<HTMLSelectElement>) {
        setCategory(event.target.value)
    }

    function doCreateExpense() {
        const dateArr = date.split('-');
        const dateObj: IDate = {
            year: dateArr[0],
            month: dateArr[1],
            day: dateArr[2]
        };

        const expenseData: IExpenseData = {
            date: dateObj,
            week: week,
            category,
            churchId,
            description,
            value
        }

        createExpense(expenseData)
            .then(success => {
                window.location.reload()
            })
    }

    const WeekSelector = () => {

        const weeks = [] as Array<string>;

        for (var i = 0; i < 7; i++) {
            var atualWeek = getWeek(new Date());
            var weekString = `${new Date().getFullYear()}-W${atualWeek - i}`;
            weeks.push(weekString);
        }

        if (validateUserAgent() === 'iOS' || validateUserAgent() === 'Mac') {
            return (
                <select name="week" id="week" value={week} onChange={handleWeekChange2}>
                    {
                        weeks.map((week: string) => {
                            var weekNumber = week.split('W')[1]; 
                            return (
                                <option value={week}>Semana {weekNumber}</option>
                            )
                        })
                    }
                </select>
            )
        } else {
            return(
                <input type="week" name="week" id="week" value={week} onChange={handleWeekChange}/>
            )
        }
    }
    
    return(
        <React.Fragment>
            <InternalHeader/>
            <Container style={{ justifyContent: 'flex-start', backgroundColor: '#F9F9F9' }}>
                <div className='user-header'>
                    <div className="title-container">
                        <a href='/interno/saidas'>🔙</a>
                        <h2>Criar Saída</h2>
                    </div>
                </div>
                <div className="user-body" id='user-body'>
                    <div className="user-wrapper">
                        <div className="new-user-wrapper">
                            <div>
                                <label htmlFor="description">DESCRIÇÃO</label>
                                <input type="text" name="description" id="description" onChange={handleDescriptionChange}/>
                            </div>
                        </div>
                        <div className="new-user-wrapper">
                            <div>
                                <label htmlFor="valor">VALOR</label>
                                <input type="number" inputMode="decimal" name="valor" id="valor" onChange={handleValueChange}/>
                            </div>
                        </div>
                        <div className="new-user-wrapper">
                            <div>
                                <label htmlFor="data">SEMANA</label>
                                <WeekSelector/>
                            </div>
                        </div>
                        <div className="new-user-wrapper">
                            <div>
                                <label htmlFor="data">DATA</label>
                                <input type="date" name="data" id="data" value={date} onChange={handleDateChange}/>
                            </div>
                        </div>
                        <div className="new-user-wrapper">
                            <div>
                                <label htmlFor="churchId">IGREJA</label>
                                <select name="churchId" id="churchId" onChange={handleChurchIdChange} value={churchId}>
                                    <option value="sede">PIB Sede</option>
                                    <option value="cohab">PIB Cohab</option>
                                    <option value="saude">PIB Saúde</option>
                                </select>
                            </div>
                        </div>
                        <div className="new-user-wrapper">
                            <div>
                                <label htmlFor="category">CATEGORIA</label>
                                <select name="category" id="category" onChange={handleCategoryChange} value={category}>
                                    <option value="none" defaultChecked>Selecione a categoria</option>
                                    <option value="GRATIFICAÇÕES">Gratificações</option>
                                    <option value="ALUGUÉIS">Aluguéis</option>
                                    <option value="PATRIMÔNIO">Patrimônio</option>
                                    <option value="REFORMA">Reforma</option>
                                    <option value="PLANO COOPERATIVO">Plano Cooperativo</option>
                                    <option value="FUNDO MINISTERIAL">Fundo Ministerial</option>
                                    <option value="ÁGUA E LUZ">Água e Luz</option>
                                    <option value="AÇÃO SOCIAL">Ação Social</option>
                                    <option value="INTERNET">Internet</option>
                                    <option value="EVENTUAIS">Eventuais</option>
                                </select>
                            </div>
                        </div>
                        <div className="new-user-wrapper save">
                            <button onClick={doCreateExpense}>Criar</button>
                        </div>
                    </div>
                </div>
            </Container>
            <Footer/>
        </React.Fragment>
    )
}

export default CreateExpense;