import React, { useState, useMemo } from 'react';
import { useParams } from "react-router-dom";

import InternalHeader from '../components/InternalHeader';
import Container from '../components/Container'
import Footer from '../components/Footer';
import { ShowChart, IDatasetData, IData } from '../components/RenderChart';

import { ReactComponent as SmallSearchIcon } from '../assets/img/small-search.svg';

import "../style/showYearData.scss";

import { ISearchData, loadYearData} from '../controllers/reports/year/loadYearData'

import LoadingPanel from '../components/LoadingPanel';

interface IRawData {
    tenths: Array<number>,
    offers: Array<number>,
    balances: Array<number>,
    entries: Array<number>,
    expenses: Array<number>
}

const ShowYearData = () => {
    var { year, church } = useParams();
    var [reports, setReports] = useState({} as IRawData);
    var reportsArray = [] as IRawData[];
    var [loading, setLoading] = useState(false);

    var normalizedChurchId = () => {
        switch (church) {
            case 'sede':
                return 'Sede'
            case 'saude':
                return 'Saúde'
            case 'cohab':
                return 'Cohab'
        }
    }

    var [tenthsData] = useState({} as IData);
    var [tenthsValues] = useState([] as Array<number>);

    var [offersData] = useState({} as IData);
    var [offersValues] = useState([] as Array<number>);

    var [entriesData] = useState({} as IData);
    var [entriesValues] = useState([] as Array<number>);

    var [expensesData] = useState({} as IData);
    var [expensesValues] = useState([] as Array<number>);

    var [balancesData] = useState({} as IData);
    var [balancesValues] = useState([] as Array<number>);

    const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

    var searchData: ISearchData = {
        year: parseInt(year as string),
        churchId: church as string
    }

    function loadData(props: ISearchData) {
        loadYearData(props)
            .then((data: IRawData) => setReports(data))
            .catch((err) => console.error(err))
    }

    // eslint-disable-next-line
    var getData = useMemo(() => loadData(searchData), reportsArray);
    document.addEventListener('DOMContentLoaded', () => getData);

    // Dízimos / Ofertas
    tenthsValues = reports.tenths;
    offersValues = reports.offers;
    
    tenthsData.data = tenthsValues;
    tenthsData.backgroundColor = "#4573c6";
    tenthsData.label = "Dízimos";
    offersData.data = offersValues;
    offersData.backgroundColor = "#ed7b30";
    offersData.label = "Ofertas";

    var tenthsOffersChartData: IDatasetData = {
        labels: months,
        datasets: [ tenthsData, offersData ],
    }

    // Entradas / Saídas
    entriesValues = reports.entries;
    expensesValues = reports.expenses;

    entriesData.data = entriesValues;
    expensesData.data = expensesValues;
    entriesData.backgroundColor = "#7ebadd";
    expensesData.backgroundColor = "#bf5e6e";
    entriesData.label = "Entradas";
    expensesData.label = "Saídas";

    var entriesOffersChartData: IDatasetData = {
        labels: months,
        datasets: [ entriesData, expensesData ],
    }

    // Saldos
    balancesValues = reports.balances;

    balancesData.data = balancesValues;
    balancesData.backgroundColor = "#117c11";
    balancesData.label = "Saldo Mensal";

    var balancesChartData: IDatasetData = {
        labels: months,
        datasets: [ balancesData ]
    }

    var [churchId, setChurchId] = useState(church);
    var [searchYear, setSearchYear] = useState(parseInt(year as string));

    function handleChurchIdChange(event: React.ChangeEvent<HTMLSelectElement>) {
        setChurchId(event.target.value);
    }

    function handleYearChange(event: React.ChangeEvent<HTMLSelectElement>) {
        setSearchYear(parseInt(event.target.value));
    }

    function search() {
        setLoading(true);
        window.location.href = window.location.protocol + "//" + window.location.host + '/interno/relatorios/acompanhamento-anual/' + churchId + '/' + searchYear;
    }


    return (
        <React.Fragment>
            <LoadingPanel loading={loading}/>
            <InternalHeader/>
            <Container style={{ backgroundColor: '#F9F9F9' }}>
                <div className='reports-header'>
                    <div className="title-container">
                        <h2>Acompanhamento PIB {normalizedChurchId()} {year}</h2>
                    </div>
                    <div id="search-container">
                        <select name="church" id="church" defaultValue={church} onChange={handleChurchIdChange}>
                            <option value="sede">PIB Sede</option>
                            <option value="cohab">PIB Cohab</option>
                            <option value="saude">PIB Saúde</option>
                        </select>
                        <select name="year" id="year" defaultValue={year} onChange={handleYearChange}>
                            <option value="2021">2021</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                        </select>
                        <button id='search-button' onClick={search}>
                            Buscar
                            <SmallSearchIcon className="search-icon"/>
                        </button>
                    </div>
                </div>
                <div className='reports-body'>
                    <div className="chart-container">
                        <ShowChart title='Dízimos e Ofertas Mensais' data={tenthsOffersChartData}/>
                    </div>
                    <div className="chart-container">
                        <ShowChart title='Entradas e Saídas Mensais' data={entriesOffersChartData}/>
                    </div>
                    <div className="chart-container">
                        <ShowChart title='Saldos Mensais' data={balancesChartData}/>
                    </div>
                </div>
            </Container>
            <Footer/>
        </React.Fragment>
    )
}

export default ShowYearData;