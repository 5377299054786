import React, { useState } from 'react';

import { authenticate, IAuthenticateData, setCookies, ICookieData } from '../controllers/user/authenticate'

import Header from '../components/Header';
import Container from '../components/Container'
import Footer from '../components/Footer';

import LoadingPanel from '../components/LoadingPanel';

import '../style/login.scss';

const Login = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);

    function handleEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
      setEmail(event.target.value);
    }

    function handlePasswordChange(event: React.ChangeEvent<HTMLInputElement>) {
      setPassword(event.target.value);
    }

    const userData:IAuthenticateData = {
      email: email,
      password: password
    }

    function authenticateUser() {
      const messageElement = document.querySelector('.message-wrapper span') as HTMLSpanElement;

      const hideErrorMessage = () => { messageElement.style.display = 'none' };
      const showErrorMessage = () => { messageElement.style.display = 'block'};

      setLoading(true);

      authenticate(userData)
        .then(response => {
          if (response.status !== 'error') {
            var cookieId: ICookieData = {
              name: 'UID',
              value: response.userData.userId
            };

            var cookieToken: ICookieData = {
              name: 'sessionToken',
              value: response.token
            };

            var cookieRole: ICookieData = {
              name: 'userRole',
              value: response.userData.role
            };

            var userChurch: ICookieData = {
              name: 'userChurch',
              value: response.userData.churchId
            };

            setCookies(cookieId);
            setCookies(cookieToken);
            setCookies(cookieRole);
            setCookies(userChurch);

            if (response.userData.role === 'administrador' || response.userData.role === 'tesoureiro' || response.userData.role === 'pastor') {
              window.location.href = '/interno/relatorios/';
            } else {
              window.location.href = '/relatorios';
            }
            
          } else {
            setLoading(false);
            if (response.errorId === 'auth_01') messageElement.innerText = "E-mail não registrado! Revise os dados e tente novamente.";
            if (response.errorId === 'auth_02') messageElement.innerText = "Senha incorreta! Revise os dados e tente novamente.";
            if (response.errorId === 'auth_03') messageElement.innerText = "Registro de usuário desativado. Entre em contato com a administração para reativar sua conta."
            
            showErrorMessage();
            setTimeout(hideErrorMessage, 3000);
          }
        })
      .catch(error => {
        setLoading(false);
        console.warn(error);
      })
    }

    return (
      <React.Fragment>
        <LoadingPanel loading={loading}/>
        <Header/>
        <Container style={{ backgroundColor: '#F9F9F9' }}>
          <div className='login-wrapper'>
            <div className='login-header'>
              <h1>Área de Login</h1>
              <span>Insira seu e-mail e senha cadastrados</span>
            </div>
            <form autoComplete='on' onSubmit={e => e.preventDefault()}>
              <div className="email-wrapper">
                <label htmlFor="email">E-MAIL</label>
                <input type="email" name="email" id="email" placeholder='usuario@example.com.br' onChange={handleEmailChange}/>
              </div>

              <div className="password-wrapper">
                <div className="password-header-wrapper">
                  <label htmlFor="password">SENHA</label>
                  <a href="/esqueci-minha-senha">Esqueceu sua senha?</a>
                </div>
                <input type="password" name="password" id="password" placeholder='***************' onChange={handlePasswordChange}/>
              </div>

              <div className="message-wrapper">
                <span>Erro ao realizar login</span>
              </div>

              <button onClick={authenticateUser}>
                Entrar
              </button>
            </form>
          </div>
        </Container>
        <Footer/>
      </React.Fragment>
    )
}

export default Login;