import React, {useState} from 'react';
import { ReactComponent as Logo } from '../assets/img/IBVC_Horizontal.svg';
import AsideMenu from './AsideMenu';
import ProfileMenu from './ProfileMenu';

import iconMenu from '../assets/img/menu.svg';
import arrowDown from '../assets/img/arrow-down.svg';

import "../style/internalHeader.scss";

const InternalHeader = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => setIsOpen(!isOpen);

    const [isOpenProfile, setIsOpenProfile] = useState(false);
    const toggleProfile = () => setIsOpenProfile(!isOpenProfile);

    return(
        <React.Fragment>
            <header className="internal-header-container">
                <div className="header-content-left">
                    <button className="menu-button" onClick={toggleMenu}>
                        <img src={iconMenu} width="24px" height="24px" alt="menu-icon" />
                    </button>
                    <Logo/>
                </div>
                <div className="header-content-right">
                    <div className="user-info">
                        <button className="user-menu" onClick={toggleProfile}>
                            <img src={arrowDown} width="16px" height="16px" alt="menu-icon" />
                        </button>
                    </div>
                </div>
            </header>
            <AsideMenu isOpen={isOpen} toggleMenu={toggleMenu}/>
            <ProfileMenu isOpen={isOpenProfile}/>
        </React.Fragment>
    )
}

export default InternalHeader;