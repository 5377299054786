import axios from 'axios';

export interface IUserData {
    name: string,
    email: string,
    password: string,
    confirmPassword: string,
    role: string,
    churchId: string,
}

export async function createUser(props: IUserData) {
    const { name, email, password, confirmPassword, role, churchId } = props;

    const response = await axios.post(
        `${process.env.REACT_APP_APIPATH}/user/create`,
        {
            name,
            email,
            password,
            confirmPassword,
            role,
            churchId
        },
        {
            Headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
        }
    );
    return response.data;
}