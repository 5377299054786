import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export interface IData {
  label: string;
  data: Array<number>;
  backgroundColor: string;
}

export interface IDatasetData {
    labels: Array<string>;
    datasets: Array<IData>;
}

interface IChartData {
    data: IDatasetData;
    title: string;
}

export function ShowChart(data: IChartData) {
  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: data.title,
      },
    },
  };
  
  const config = {
    labels: data.data.labels,
    datasets: data.data.datasets
  };
  
 
  return <Bar options={options} data={config}/>;
}


