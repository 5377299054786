import React, {useState, useMemo, useRef} from "react";
import { useParams } from "react-router-dom";
import { jsPDF } from 'jspdf';

import { loadWeekReport, ISearchData, IReportData } from "../controllers/reports/week/loadWeekReport";
import { validateUserAgent } from '../controllers/misc/validateUserAgent';

import "../style/showReport.scss";

function capitalize(string: string): string {
    return string && string[0].toUpperCase() + string.slice(1);
}

const ShowWeekReport = () => {
    const pdfRef = useRef(null);
    const month = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
    const fullMonth = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
    var [reports, setReports] = useState([] as Array<IReportData>);
    var { formId } = useParams();

    function getReport(props: ISearchData) {
        loadWeekReport(props)
            .then( (data: IReportData) => setReports([data]))
            .catch( err => console.log(err) )
    }

    function savePDF() {

        if (validateUserAgent() === 'iOS') {
            document.title = `Relatório Financeiro ${reports[0].weekData.weekNumber}ª Semana ${fullMonth[reports[0].weekData.monthId]} - PIB ${capitalize(reports[0].churchId)}`;
            window.print();
        } else {
            var doc = new jsPDF('portrait', 'pt', 'a4');
            const content = pdfRef.current as unknown;
    
            doc.html(
                content as HTMLDivElement, 
                {
                    margin: 40,
                    html2canvas: { scale: 0.7 },
                    x: 10,
                    y: 10,
                    callback: function (doc) {
                        doc.save(`Relatório Financeiro ${reports[0].weekData.weekNumber}ª Semana ${fullMonth[reports[0].weekData.monthId]} - PIB ${capitalize(reports[0].churchId)}`);
                        window.close();
                    }
                }
            );
        }
        
    }

    function autoPrintReport() {
        setTimeout(savePDF, 1000);
    }

    var searchData = { formId: formId } as ISearchData;

    // eslint-disable-next-line
    var searchReport = useMemo(() => getReport(searchData), reports)
    
    document.addEventListener('DOMContentLoaded', () => searchReport);
   
    return (
        <React.Fragment>
            {
                reports.map((report: IReportData) => {
                    return (
                        <React.Fragment>
                            <div className="report-container" ref={pdfRef} style={{ width: "700px" }}>
                                <div className="report-title">
                                    <h2 key={report._id} style={{ fontFamily: "Arial" }}>Relatório&nbsp;&nbsp;Financeiro&nbsp;&nbsp;{report.weekData.weekNumber}ª&nbsp;&nbsp;Semana&nbsp;&nbsp;{month[report.weekData.monthId]}/{report.weekData.year} - PIB {capitalize(report.churchId)}</h2>
                                </div>
                                <div className="report-body">
                                    <div className="report-wrapper">
                                        <div className="report-header">
                                            <div className="row">
                                                <span style={{ fontFamily: "Helvetica" }}><b>ID da Planilha:</b> {report.formId}</span>
                                                <span style={{ fontFamily: "Helvetica" }}><b>Data:</b> { new Date(report.createdAt).toLocaleDateString('pt-BR', {timeZone: 'UTC'}) }</span>
                                            </div>
                                            <div className="row">
                                                <span style={{ fontFamily: "Helvetica" }}><b>Executado por:</b> {report.creatorData.name}</span>
                                                <span style={{ fontFamily: "Helvetica" }}><b>Departamento:</b> Tesouraria</span>
                                            </div>
                                        </div>
                                        
                                        <div className="row">
                                            <table className="main-table">
                                                <tr className="bordered">
                                                    <td className="entries">
                                                        <div className="column">
                                                            <table>
                                                                <thead>
                                                                    <tr>
                                                                        <th style={{ fontFamily: "Helvetica" }}>Entrada</th>
                                                                        <th style={{ fontFamily: "Helvetica" }}>Valor</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ fontFamily: "Helvetica" }}>Dízimos</td>
                                                                        <td style={{ fontFamily: "Helvetica" }}>{parseFloat(report.tenths).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ fontFamily: "Helvetica" }}>Ofertas</td>
                                                                        <td style={{ fontFamily: "Helvetica" }}>{parseFloat(report.offers).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </td>
                                                    <td className="bordered">
                                                        <div className="column" style={{ minWidth: '400px', minHeight: '400px' }}>
                                                            <table>
                                                                <thead>
                                                                    <tr>
                                                                        <th style={{ fontFamily: "Helvetica" }}>Saída</th>
                                                                        <th style={{ fontFamily: "Helvetica" }}>Data</th>
                                                                        <th style={{ fontFamily: "Helvetica" }}>Valor</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        report.expenses.map(( expenseArr ) => {
                                                                            var date = expenseArr[0].date;
                                                                            if (date.day) {
                                                                                return (
                                                                                    <tr>
                                                                                        <td style={{ fontFamily: "Helvetica" }}>{expenseArr[0].description}</td>
                                                                                        <td style={{ fontFamily: "Helvetica" }}>{date.day}/{date.month}</td>
                                                                                        <td style={{ fontFamily: "Helvetica" }}>{expenseArr[0].value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                                                    </tr>
                                                                                )
                                                                            } else {
                                                                                var oldDate = date as unknown;
                                                                                var newDate = oldDate as string;

                                                                                var arrDate = newDate.split('-');

                                                                                return (
                                                                                    <tr>
                                                                                        <td style={{ fontFamily: "Helvetica" }}>{expenseArr[0].description}</td>
                                                                                        <td style={{ fontFamily: "Helvetica" }}>{arrDate[2]}/{arrDate[1]}</td>
                                                                                        <td style={{ fontFamily: "Helvetica" }}>{expenseArr[0].value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                                                    </tr>
                                                                                )
                                                                            }
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>

                                        <div className="report-resume">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ fontFamily: "Helvetica" }}><b>Total de Entradas</b></td>
                                                        <td style={{ fontFamily: "Helvetica" }}>{(parseFloat(report.tenths) + parseFloat(report.offers)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                        <td style={{ fontFamily: "Helvetica" }}><b>Total de Saídas</b></td>
                                                        <td style={{ fontFamily: "Helvetica" }}>{parseFloat(report.totalExpenses).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontFamily: "Helvetica" }}><b>Saldo Anterior</b></td>
                                                        <td style={{ fontFamily: "Helvetica" }}>{parseFloat(report.lastBalance).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                        <td style={{ fontFamily: "Helvetica" }}><b>Saldo Atual</b></td>
                                                        <td style={{ fontFamily: "Helvetica" }}>{parseFloat(report.balance).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )
                }, autoPrintReport())
            }
        </React.Fragment>
    )
}

export default ShowWeekReport;