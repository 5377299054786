import axios from 'axios';

export interface IMonthFormData {
    churchId: string;
    monthId: number;
    year: number;
}

export async function deleteMonthReport (props: IMonthFormData) {
    const months = ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'];
    const { churchId, monthId, year } = props;

    const formId = `mensal-${months[monthId]}${year}-${churchId}`;

    const response = await axios.post(
        `${process.env.REACT_APP_APIPATH}/form/month/delete`,
        {
            formId: formId
        },
        {
            Headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
        }
    )

    return response.data;
}