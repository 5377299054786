import React from 'react';

import '../style/mainTitle.scss'

export interface MainTitleProps {
    children: React.ReactNode;
}

const MainTitle = (props: MainTitleProps) => {
    return (
        <h1 className='main-title'>
            {props.children}
        </h1>
    )
};

export default MainTitle;