import axios from "axios";

import { getCookies, removeCookies } from "./authenticate";

const restrictedUrls = ['/relatorios'];
const staffUrls = [ 
    '/interno/relatorios',
    '/interno/relatorios/semanal/novo-relatorio', 
    '/interno/relatorios/mensal/novo-relatorio',
    '/interno/administracao/remover-relatorio', 
    '/interno/administracao/gerenciar-usuarios', 
    '/interno/administracao/novo-usuario',
    '/interno/pagamentos',
    '/interno/saidas',
    '/interno/saidas/nova-saida',
    '/interno/entradas',
    '/interno/entradas/nova-entrada',

];
const authenticationUrls = [
    '/login', 
    '/esqueci-minha-senha'
];

window.addEventListener('load', async () => {

    const token = getCookies('sessionToken');

    if (restrictedUrls.includes(window.location.pathname)) {
        if (token) {
            axios.post(
                `${process.env.REACT_APP_APIPATH}/user/validatesession`,
                {
                    token: token
                },
                {
                    Headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json'
                    }
                }
            )
            .then((response: any) => {
                if (response.data.email) {
                    console.log("Session is valid");
                } else {
                    removeCookies('sessionToken');
                    removeCookies('UID');
                    window.location.href = '/login'
                }
            })
            .catch((error: any) => {
                removeCookies('sessionToken');
                removeCookies('UID');
                window.location.href = '/login'
            })
        } else {
            window.location.href = '/login'
        }
    }

    if (authenticationUrls.includes(window.location.pathname)) {
        if (token) {
            console.log(token);
            axios.post(
                `${process.env.REACT_APP_APIPATH}/user/validatesession`,
                {
                    token: token
                },
                {
                    Headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json'
                    }
                }
            ).then((response: any) => {
                if (response.data.name !== "TokenExpiredError") {
                    console.log('logado')
                    window.location.href = restrictedUrls[0];
                } else {
                    removeCookies('sessionToken');
                    removeCookies('UID');
                    return false;
                }
            }).catch((error: any) => {
                removeCookies('sessionToken');
                removeCookies('UID');
                return false;
            })
        } else {
            return false;
        }
    }

    if (staffUrls.includes(window.location.pathname)) {
        if (token) {
            axios.post(
                `${process.env.REACT_APP_APIPATH}/user/validatesession`,
                {
                    token: token
                },
                {
                    Headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json'
                    }
                }
            )
            .then((response: any) => {
                if (response.data.email) {
                    console.log("Session is valid");
                    if (response.data.role === 'administrador' || response.data.role === 'tesoureiro' || response.data.role === 'pastor'){
                        console.log("User role is valid")
                    } else {
                        window.location.href = '/relatorios';
                    }
                } else {
                    removeCookies('sessionToken');
                    removeCookies('UID');
                    window.location.href = '/login'
                }
            })
            .catch((error: any) => {
                removeCookies('sessionToken');
                removeCookies('UID');
                window.location.href = '/login'
            })
        } else {
            window.location.href = '/login'
        }
    }    
})