import axios from "axios";

export interface ISearchData {
    year: number;
    churchId: string
}

export interface IMonthData {
    monthId: number,
    year: number
}
export interface IResponseData {
    formId: string;
    monthData: IMonthData;
    tenths: number;
    offers: number;
    balance: number;
    totalEntries: number;
    totalExpenses: number;
}

export async function loadYearData(props: ISearchData) {
    const { year, churchId } = props;

    const response = await axios.post(
        `${process.env.REACT_APP_APIPATH}/form/year`,
        {
            year,
            churchId
        },
        {
            Headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
        }
    );

    return response.data;
}