import React from 'react';
import '../style/container.scss';

export interface ContainerProps {
    children: React.ReactNode;
    style?: React.CSSProperties;
}

const Container = (props: ContainerProps) => {
    const { children, style } = props;


    return (
        <section className='container-element' style={style}>
            {children}
        </section>
    )
};

export default Container;