import React, {useState, useMemo} from 'react';

import InternalHeader from '../components/InternalHeader';
import Container from '../components/Container'
import Footer from '../components/Footer';

import { getUsers, IUserData } from '../controllers/user/getUsers';
import { activateUser, deactivateUser, updateData, IToggleActivation, IUpdateData } from '../controllers/user/updateUser';

import "../style/manageUsers.scss";

const ManageUsers = () => {

    var isMobile = navigator.userAgent.match(/(?:phone|windows\s+phone|ipod|blackberry|(?:android|bb\d+|meego|silk|googlebot) .+? mobile|palm|windows\s+ce|opera mini|avantgo|mobilesafari|docomo|KAIOS)/i) != null || navigator.userAgent.match(/(?:ipad|playbook|(?:android|bb\d+|meego|silk)(?! .+? mobile))/i) != null;

    var months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
    var [users, setUsers] = useState(Array<IUserData>);
    var [dataToUpdate, setDataToUpdate] = useState("");
    var [labelToUpdate, setLabelToUpdate] = useState("");
    var [userIdToUpdate, setUserIdToUpdate] = useState("");
    var [newDataValue, setNewDataValue] = useState("");

    // eslint-disable-next-line
    const loadUsers = useMemo(() => getUsers().then(users => { setUsers(users); }), users);

    window.addEventListener('load', () => loadUsers);

    getUsers()
        .then(response => {
            users = response;
        })

    function renderHeader() {
        if (isMobile) {
            return (
                <React.Fragment>
                    <th>Nome</th>
                    <th>E-mail</th>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <th>Nome</th>
                    <th>E-mail</th>
                    <th>Cargo</th>
                    <th>Igreja</th>
                    <th>Status</th>
                    <th>Última Atualização</th>
                </React.Fragment>
            )
        }
    }

    
    function handleDataSet(event: React.ChangeEvent<HTMLInputElement>) {
        setNewDataValue(event.target.value);
    }

    function handleDataChange(event: React.ChangeEvent<HTMLSelectElement>) {
        setNewDataValue(event.target.value);
        console.log({
            value: newDataValue
        })
    }

    function renderEditionField() {
        if (dataToUpdate === 'role') {
            return (
                <select name="role" id="role" onChange={handleDataChange}>
                    <option value="membro">Membro</option>
                    <option value="administrador">Administrador</option>
                    <option value="tesoureiro">Tesoureiro</option>
                    <option value="pastor">Pastor</option>
                </select>
            )
        } else if (dataToUpdate === 'isActive') {
            return (
                <select name="isActive" id="isActive" onChange={handleDataChange}>
                    <option value="true">Ativo</option>
                    <option value="false">Inativo</option>
                </select>
            )
        } else {
            return (
                <input type="email" name="newValue" id="newValue" onChange={handleDataSet}/>
            )
        }
    }

    function saveNewData() {
        console.log({
            id: userIdToUpdate,
            key: dataToUpdate,
            value: newDataValue
        })

        if (dataToUpdate === "isActive") {
            var data = {} as IToggleActivation;
            data.id = userIdToUpdate;

            switch (newDataValue) {
                case "false":
                    deactivateUser(data)
                        .then(() => {
                            window.location.reload();
                        });
                    break;
                default:
                    activateUser(data)
                        .then(() => {
                            window.location.reload();
                        });
                    break;
            }
        } else {
            var newData = {} as IUpdateData;
            newData.id = userIdToUpdate;
            newData.key = dataToUpdate;
            newData.newvalue = newDataValue;

            updateData(newData)
                .then(response => {
                    const messageElement = document.querySelector('.message-wrapper span') as HTMLSpanElement;

                    const hideErrorMessage = () => { messageElement.style.display = 'none' };
                    const showErrorMessage = () => { messageElement.style.display = 'block'};

                    if (response.codeName === "DuplicateKey") {
                        showErrorMessage();
                        setTimeout(hideErrorMessage, 3000);
                    } else {
                        window.location.reload();
                        setNewDataValue("");
                    }

                })
        }
    }



    function closeModal() {
        var parentDiv = document.querySelector('.update-data-wrapper') as HTMLDivElement;
        parentDiv.style.zIndex = "-1";
    }

    function openEdition(event: React.MouseEvent<HTMLTableCellElement>) {
        var element = event.target as HTMLTableCellElement;
        setUserIdToUpdate(element.dataset.userId as string);

        if (element.classList.contains('email')) {
            setDataToUpdate('email')
            setLabelToUpdate('e-mail')
        } else if (element.classList.contains('role')) {
            setDataToUpdate('role')
            setLabelToUpdate('cargo')
            setNewDataValue('membro')
        } else {
            setDataToUpdate('isActive')
            setLabelToUpdate('status')
            setNewDataValue('true')
        }

        var modal = document.querySelector('.update-data-wrapper') as HTMLDivElement;
        modal.style.zIndex = "10";

    }

    return (
        <React.Fragment>
            <InternalHeader/>
            <Container style={{ justifyContent: 'flex-start', backgroundColor: "#f9f9f9" }}>
                <div className='users-header'>
                    <div className="title-container">
                        <h2>Gerenciamento de Usuários</h2>
                        <a href='/interno/administracao/novo-usuario'>➕</a>
                    </div>
                </div>

                <div className='users-container'>
                    <table className='users-table'>
                        <thead>
                            {
                                renderHeader()
                            }
                        </thead>
                        <tbody>
                            {
                                users.map(user => {
                                    var date = new Date(user.updatedAt);

                                    function renderData() {
                                        if (isMobile) {
                                            return (
                                                <React.Fragment>
                                                    <td>{user.name}</td>
                                                    <td>{user.email}</td>
                                                </React.Fragment>
                                            )
                                        } else {
                                            return (
                                                <React.Fragment>
                                                    <td>{user.name}</td>
                                                    <td data-user-id={user._id} className='email editable' onClick={openEdition}>{user.email}</td>
                                                    <td data-user-id={user._id} className='role editable' onClick={openEdition}>{user.role}</td>
                                                    <td>{user.churchId.toLocaleUpperCase()}</td>
                                                    <td data-user-id={user._id} className='is-active editable' onClick={openEdition}>{user.isActive ? 'Ativo' : 'Inativo'}</td>
                                                    <td>{date.getDate()}/{months[date.getMonth()]}/{date.getFullYear()}</td>
                                                </React.Fragment>
                                            )
                                        }
                                    }

                                    return (
                                        <tr>
                                            {
                                                renderData()
                                            }
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </Container>
            <Footer/>
            <div className="update-data-wrapper">
                <button className='close-modal' onClick={closeModal} >x</button>
                <div className="update-data-container">
                    <span className='main-label'>Insira o novo {labelToUpdate} do usuário:</span>
                    {
                        renderEditionField()
                    }
                    <div className="message-wrapper">
                        <span>E-mail já cadastrado</span>
                    </div>
                    <button className='update-data' onClick={saveNewData}>Salvar</button>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ManageUsers;