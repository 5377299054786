import axios from 'axios';

export interface IFilterData {
    entryId: string;
}

export async function loadEntry(props: IFilterData) {
    const { entryId } = props;

    const response = await axios.post(
        `${process.env.REACT_APP_APIPATH}/entry/load`,
        {
            entryId: entryId
        },
        {
            Headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
        }
    );

    return response.data;
}