import axios from 'axios';

export interface IDecimistData {
    name: string;
    tenth: number;
}

export interface IEntryData {
    week: string;
    churchId: string;
    offers: number;
    decimists: Array<IDecimistData>;
}

export async function createEntry(props: IEntryData) {
    const { week, churchId, offers, decimists } = props;

    const response = await axios.post(
        `${process.env.REACT_APP_APIPATH}/entry/create`,
        {
            week: week,
            churchId: churchId,
            offers: offers,
            decimists: decimists
        },
        {
            Headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
        }
    );

    return response.data;
}