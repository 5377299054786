import React, { useState } from 'react';

import Header from '../components/Header';
import Container from '../components/Container'
import Footer from '../components/Footer';

import '../style/resetPassword.scss';

import { resetPassword, IPasswordData } from '../controllers/user/resetPassword';
import { validatePassword } from '../controllers/user/validatePassword';

const ResetPassword = () => {
    const urlParams = new URLSearchParams(window.location.search);

    if (!urlParams.has('t') || !urlParams.has('uid')) {
        window.location.href = '/esqueci-minha-senha';
    }

    const [ password, setPassword ] = useState('');
    const [ confirmPassword, setConfirmPassword ] = useState('');

    function handlePasswordChange(event: React.ChangeEvent<HTMLInputElement>) {
        setPassword(event.target.value);
    }

    function handleConfirmPasswordChange(event: React.ChangeEvent<HTMLInputElement>) {
        setConfirmPassword(event.target.value);
    }

    function savePassword() {
        const messageElement = document.querySelector('.message-wrapper span') as HTMLSpanElement;

        const hideErrorMessage = () => { messageElement.style.display = 'none' };
        const showErrorMessage = () => { messageElement.style.display = 'block'};

        if (password !== confirmPassword) {
            messageElement.innerHTML = "Senha e confirmação da senha precisam ser iguais";
            showErrorMessage();
            setTimeout(hideErrorMessage, 5000);
        } else if (!validatePassword(password)) {
            messageElement.innerHTML = "Senha precisa ter no mínimo 8 caracteres, um número, uma letra maiúscula e um caractere especial (#?!@$ %^&*-)";
            showErrorMessage();
            setTimeout(hideErrorMessage, 5000);
        } else {

            const resetPasswordData: IPasswordData = {
                userId: urlParams.get('uid') as string,
                token: urlParams.get('t') as string,
                newPassword: password
            }

            resetPassword(resetPasswordData)
                .then((response: any) => {
                    if (response.failed) {
                        messageElement.innerHTML = "Solicitação expirada. Por favor, tente novamente";
                        showErrorMessage();
                        setTimeout(() => { window.location.href = '/esqueci-minha-senha' }, 5000);
                    } else {
                        messageElement.innerHTML = "Senha alterada com sucesso! Estamos te redirecionando para o login...";
                        messageElement.classList.add('success');
                        showErrorMessage();
                        setTimeout(() => { window.location.href = '/login' }, 5000)
                    }
                })
                .catch((error: any) => {
                    console.warn(error);
                })
        }
    }

    return (
        <React.Fragment>
            <Header/>
            <Container style={{ backgroundColor: '#F9F9F9' }}>
                <div className='reset-password-wrapper'>
                    <div className='reset-password-header'>
                        <h1>Redefinição de Senha</h1>
                        <span>Insira seu e-mail cadastrado e te daremos instruções de como recuperar sua senha</span>
                    </div>
                    <form autoComplete='off' onSubmit={e => e.preventDefault()}>
                        <div className="password-wrapper">
                            <label htmlFor="email">NOVA SENHA</label>
                            <input type="password" name="password" id="password" placeholder='***************' onChange={handlePasswordChange}/>
                        </div>

                        <div className="password-wrapper">
                            <label htmlFor="email">CONFIRMAR NOVA SENHA</label>
                            <input type="password" name="passwordConfirm" id="passwordConfirm" placeholder='***************' onChange={handleConfirmPasswordChange}/>
                        </div>

                        <div className="message-wrapper">
                            <span>Erro ao resetar senha</span>
                        </div>

                        <button type="submit" onClick={savePassword}>
                            Salvar Nova Senha
                        </button>
                    </form>
                </div>
            </Container>
            <Footer/>
        </React.Fragment>
    )
}

export default ResetPassword;