import React, { useState } from 'react';
import { getCookies } from '../controllers/user/authenticate';

import InternalHeader from '../components/InternalHeader';
import Container from '../components/Container';
import Footer from '../components/Footer';

import LoadingPanel from '../components/LoadingPanel';

import { IExpenseData, IReportData, IWeekData, createWeekReport } from '../controllers/reports/week/createWeekReport';
import { IIterateData, iterateExpenses } from '../controllers/expenses/iterateExpenses';
import { validateUserAgent } from '../controllers/misc/validateUserAgent';

import '../style/reports.scss';

function getWeek(date: Date) {
    var date = new Date(date.getTime());
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    var week1 = new Date(date.getFullYear(), 0, 4);
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
}

const CreateWeekReport = () => {
    var [churchId, setChurchId] = useState('sede');
    var [weekNumber, setWeekNumber] = useState(1);
    var [monthId, setMonthId] = useState(0);
    var [year, setYear] = useState(2021);
    var [tenths, setTenths] = useState(0);
    var [offers, setOffers] = useState(0);

    const [week, setWeek] = useState(`${new Date().getFullYear()}-W${getWeek(new Date())}`);

    const [expenses, setExpenses] = useState([] as Array<IExpenseData>);

    const [loading, setLoading] = useState(false);

    function handleChurchIdChange(event: React.ChangeEvent<HTMLSelectElement>) {
        setChurchId(event.target.value);
    }

    function handleWeekChange(event: React.ChangeEvent<HTMLSelectElement>) {
        setWeekNumber(parseInt(event.target.value));
    }

    function handleMonthIdChange(event: React.ChangeEvent<HTMLSelectElement>) {
        setMonthId(parseInt(event.target.value));
    }

    function handleYearChange(event: React.ChangeEvent<HTMLSelectElement>) {
        setYear(parseInt(event.target.value));
    }

    function handleTenthsChange(event: React.ChangeEvent<HTMLInputElement>) {
        setTenths(parseFloat(event.target.value));
    }

    function handleOffersChange(event: React.ChangeEvent<HTMLInputElement>) {
        setOffers(parseFloat(event.target.value));
    }

    function handleWeekValue(event: React.ChangeEvent<HTMLInputElement>) {
        setWeek(event.target.value);
    }

    function handleWeekValue2(event: React.ChangeEvent<HTMLSelectElement>) {
        setWeek(event.target.value);
    }

    const WeekSelector = () => {

        const weeks = [] as Array<string>;

        for (var i = 0; i < 7; i++) {
            var atualWeek = getWeek(new Date());
            var weekString = `${new Date().getFullYear()}-W${atualWeek - i}`;
            weeks.push(weekString);
        }

        if (validateUserAgent() === 'iOS' || validateUserAgent() === 'Mac') {
            return (
                <select name="week" id="week" value={week} onChange={handleWeekValue2}>
                    {
                        weeks.map((week: string) => {
                            var weekNumber = week.split('W')[1]; 
                            return (
                                <option value={week}>Semana {weekNumber}</option>
                            )
                        })
                    }
                </select>
            )
        } else {
            return(
                <input type="week" name="week" id="week" value={week} onChange={handleWeekValue}/>
            )
        }
    }

    function doSearchExpenses() {

        const searchData:IIterateData = {
            week: week,
            churchId: churchId 
        }

        const expensesMessageElement = document.querySelector('.expenses-message-wrapper span') as HTMLSpanElement;

        const hideErrorMessage = () => { expensesMessageElement.style.display = 'none' };
        const showErrorMessage = () => { expensesMessageElement.style.display = 'block'};

        setLoading(true);

        iterateExpenses(searchData)
            .then((results) => {
                if (results.length > 0) {
                    setLoading(false);
                    setExpenses(results);

                    expenses.forEach(expense => {
                        var rowContent = document.createElement('li') as HTMLLIElement;
        
                        rowContent.innerHTML = `<b>${expense.description}</b><span>R$ ${expense.value}</span><span>${expense.date.day}/${expense.date.month}</span>`;
        
                        document.getElementById('expenses-list')?.appendChild(rowContent);
                    })
                } else {
                    setLoading(false);
                    showErrorMessage();
                    setTimeout(hideErrorMessage, 3000);
                }
            })
            .catch((err) => {
                setLoading(false);
                console.warn(err);
            })
    }

    function createReport() {
        var weekData = {} as IWeekData;
        var reportData = {} as IReportData;

        var messageElement = document.querySelector('.message-wrapper span') as HTMLSpanElement;

        weekData.weekNumber = weekNumber;
        weekData.monthId = monthId;
        weekData.year = year;

        reportData.churchId = churchId;
        reportData.creatorId = getCookies('UID');
        reportData.weekData = weekData;
        reportData.tenths = tenths;
        reportData.offers = offers;
        reportData.expenses = expenses;

        setLoading(true);

        createWeekReport(reportData)
            .then(response => {
                if (response.errorId) {
                    setLoading(false);
                    messageElement.style.display = 'flex'
                    setTimeout(() => {
                        messageElement.style.display = 'none'
                    }, 3000)
                } else {
                    let reportUrl = window.location.protocol + "//" + window.location.host + '/relatorios/semanal/' + response.formId;
                    let win = window.open(reportUrl, '_blank');
                    win?.focus();
                    let retutnUrl = window.location.protocol + "//" + window.location.host + '/interno/relatorios';
                    window.location.href = retutnUrl;
                }
            })
            .catch(error => {
                setLoading(false);
                console.log(error)
            })
    }

    return (
        <React.Fragment>
        <LoadingPanel loading={loading}/>
            <InternalHeader/>
            <Container style={{ justifyContent: 'flex-start', backgroundColor: '#F9F9F9' }}>
                <div className='reports-header'>
                    <div className="title-container">
                        <h2>Novo Formulário Semanal</h2>
                    </div>
                    <div id='search-container'>
                        <div className="search-wrapper">
                            <select name="church" id="church" onChange={handleChurchIdChange}>
                                <option value="sede">PIB Sede</option>
                                <option value="cohab">PIB Cohab</option>
                                <option value="saude">PIB Saúde</option>
                            </select>

                            <select name="week" id="week" onChange={handleWeekChange}>
                                <option value="1">1ª Semana</option>
                                <option value="2">2ª Semana</option>
                                <option value="3">3ª Semana</option>
                                <option value="4">4ª Semana</option>
                                <option value="5">5ª Semana</option>
                            </select>
                        </div>

                        <div className="search-wrapper">
                            <select name="month" id="month" onChange={handleMonthIdChange}>
                                <option value="0">Janeiro</option>
                                <option value="1">Fevereiro</option>
                                <option value="2">Março</option>
                                <option value="3">Abril</option>
                                <option value="4">Maio</option>
                                <option value="5">Junho</option>
                                <option value="6">Julho</option>
                                <option value="7">Agosto</option>
                                <option value="8">Setembro</option>
                                <option value="9">Outubro</option>
                                <option value="10">Novembro</option>
                                <option value="11">Dezembro</option>
                            </select>

                            <select name="year" id="year" onChange={handleYearChange}>
                                <option value="2021">2021</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="reports-body" id='reports-body'>
                    <div className="new-report-wrapper">
                        <div className="tenths-wrapper">
                            <label htmlFor="tenths">DÍZIMOS</label>
                            <input type="number" inputMode="decimal" name="tenths" id="tenths" placeholder='1000.00' onChange={handleTenthsChange}/>
                        </div>
                        <div className="offers-wrapper">
                            <label htmlFor="offers">OFERTAS</label>
                            <input type="number" inputMode="decimal" name="offers" id="offers" placeholder='800.00' onChange={handleOffersChange}/>
                        </div>
                    </div>

                    <div className="expenses-wrapper reports-header" style={{ display: 'flex', alignItems: 'center' }}>
                        <div id="search-container">
                            <div className="search-wrapper">
                                <WeekSelector/>
                            </div>
                            <div className="search-wrapper">
                                <button id='search-button' onClick={() => doSearchExpenses()}>
                                    ➕
                                </button>
                            </div>
                        </div>
                        <div className="expenses-message-wrapper message-wrapper">
                            <span>Nenhuma saída encontrada com os filtros selecionados</span>
                        </div>

                        <div className="new-report-wrapper">
                            <div className="expenses-table">
                                <ul id='expenses-list'>

                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="new-report-wrapper" style={{ justifyContent: 'center' }}>
                        <div className="message-wrapper">
                            <span>Formulário semanal já existe. Confira os dados da semana e tente novamente!</span>
                        </div>
                    </div>

                    <div className="new-report-wrapper save">
                        <button onClick={createReport}>
                            Salvar
                        </button>
                    </div>
                    
                </div>
            </Container>
            <Footer/>
        </React.Fragment>
    )
}

export default CreateWeekReport;
