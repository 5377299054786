import { removeCookies } from '../controllers/user/authenticate';

import iconExit from '../assets/img/exit-icon.svg';

import "../style/profileMenu.scss";

interface ProfileMenuProps {
    isOpen: boolean;
}

const ProfileMenu = (props:ProfileMenuProps) => {

    const handleLogout = () => {
        removeCookies("token");
        removeCookies("UID");
        window.location.href = "/relatorios";
    }

    return (
        <div className={props.isOpen ? "profile-menu-container open" : "profile-menu-container"}>
            <div className="profile-menu-content">
                <ul className="profile-menu-list">
                    <li className="profile-menu-item">
                        <button className="menu-button" onClick={handleLogout}>
                            <img src={iconExit} alt="exit-menu" />
                            <b>Fechar Painel</b>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default ProfileMenu