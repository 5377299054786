export function validateUserAgent() {
    var userAgent = navigator.userAgent || navigator.vendor ;

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent)) {
        return "iOS";
    }

    if (userAgent.includes("Mac OS X")) {
        return "Mac";
    }

    return "unknown";
}