import React, { useState, useMemo } from "react";

import InternalHeader from '../components/InternalHeader';
import Container from '../components/Container';
import Footer from '../components/Footer';
import LoadingPanel from '../components/LoadingPanel';
import { ReactComponent as SmallSearchIcon } from '../assets/img/small-search.svg';

import { listExpenses } from '../controllers/expenses/listExpenses';
import { IDate } from '../controllers/expenses/createExpense';
import { IIterateData, iterateExpenses } from '../controllers/expenses/iterateExpenses';
import { IRemoveData, removeExpense } from '../controllers/expenses/removeExpense';
import { validateUserAgent } from '../controllers/misc/validateUserAgent';

function capitalize(string: string): string {
    return string && string[0].toUpperCase() + string.slice(1);
}

function formatPrice(price: number): string {
    return price.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
}

interface IExpense {
    _id: string,
    churchId: string,
    description: string,
    value: number,
    week: string,
    date: IDate,
    category: string,
    isUsed: boolean,
}

const ManageExpense = () => {

    const [churchId, setChurchId] = useState("sede");
    const [expenses, setExpenses] = useState([]);
    const [loading, setLoading] = useState(false);

    // eslint-disable-next-line
    const InitialExpenses = useMemo(() => listExpenses().then(expenses => { setExpenses(expenses)}), expenses);
    const [week, setWeek] = useState(`${new Date().getFullYear()}-W${getWeek(new Date())}`)

    function handleChurchIdValue(event: React.ChangeEvent<HTMLSelectElement>) {
        setChurchId(event.target.value)
    }

    function handleWeekChange(event: React.ChangeEvent<HTMLInputElement>) {
        setWeek(event.target.value);
        console.log(week)
    }

    function handleWeekChange2(event: React.ChangeEvent<HTMLSelectElement>) {
        setWeek(event.target.value);
        console.log(week)
    }

    function getWeek(date: Date) {
        var date = new Date(date.getTime());
        date.setHours(0, 0, 0, 0);
        date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
        var week1 = new Date(date.getFullYear(), 0, 4);
        return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
    }

    function doSearchExpenses() {

        const searchData:IIterateData = {
            churchId: churchId,
            week: week
        }

        const messageElement = document.querySelector('.message-wrapper span') as HTMLSpanElement;

        const hideErrorMessage = () => { messageElement.style.display = 'none' };
        const showErrorMessage = () => { messageElement.style.display = 'block'};

        setLoading(true);

        iterateExpenses(searchData)
            .then((results) => {
                if (results.length > 0) {
                    setLoading(false);
                    setExpenses(results);
                } else {
                    setLoading(false);
                    showErrorMessage();
                    setTimeout(hideErrorMessage, 3000);
                }
            })
            .catch((err) => {
                setLoading(false);
                console.warn(err);
            })

    }

    function excludeExpense(event: React.MouseEvent<HTMLElement>) {
        let button = event.target as HTMLElement;
        let expenseId = button.dataset.id?.toString();

        let excludeData:IRemoveData = {
            id: expenseId!
        }

        //console.log(excludeData);

        removeExpense(excludeData)
            .then(sucess => window.location.reload())
            .catch(err => console.warn(err));
    }

    const WeekSelector = () => {

        const weeks = [] as Array<string>;

        for (var i = 0; i < 7; i++) {
            var atualWeek = getWeek(new Date());
            var weekString = `${new Date().getFullYear()}-W${atualWeek - i}`;
            weeks.push(weekString);
        }

        if (validateUserAgent() === 'iOS' || validateUserAgent() === 'Mac') {
            return (
                <select name="week" id="week" value={week} onChange={handleWeekChange2}>
                    {
                        weeks.map((week: string) => {
                            var weekNumber = week.split('W')[1]; 
                            return (
                                <option value={week}>Semana {weekNumber}</option>
                            )
                        })
                    }
                </select>
            )
        } else {
            return(
                <input type="week" name="week" id="week" value={week} onChange={handleWeekChange}/>
            )
        }
    }

    return (
        <React.Fragment>
            <LoadingPanel loading={loading}/>
            <InternalHeader/>
            <Container style={{ justifyContent: 'flex-start', backgroundColor: '#F9F9F9' }}>
                <div className='reports-header' style={{ justifyContent: "center" }}>
                    <div className="title-container">
                        <h2>Saídas Gerais</h2>
                        <a href='/interno/saidas/nova-saida'>➕</a>
                    </div>
                    <div id="search-container">
                        <div className="search-wrapper">
                            <select name="church" id="church" onChange={handleChurchIdValue}>
                                <option value="sede">PIB Sede</option>
                                <option value="cohab">PIB Cohab</option>
                                <option value="saude">PIB Saúde</option>
                            </select>
                            <WeekSelector/>
                        </div>
                        <div className="search-wrapper">
                            <button id='search-button' onClick={() => doSearchExpenses()}>
                                Buscar
                                <SmallSearchIcon className="search-icon"/>
                            </button>
                        </div>
                    </div>
                    <div className="message-wrapper">
                        <span>Nenhuma saída encontrada com os filtros selecionados</span>
                    </div>
                </div>
                {
                    expenses.length > 0
                    ? <div className="reports-body" id='reports-body'>
                    {
                            expenses.map((expense: IExpense) => {
                                return (
                                    <div className='report-element expense' data-id={expense._id}>
                                        <div className="report-body">
                                            <div className="report-wrapper">
                                                <b>Descrição</b>
                                                <span>{expense.description}</span>
                                            </div>
                                            <div className="report-wrapper">
                                                <b>PIB {capitalize(expense.churchId)}</b>
                                                <b>SEM {expense.week.split('-')[1].replace("W", "").padStart(2, "0")}/{expense.week.split('-')[0]}</b>
                                            </div>
                                            <div className='report-wrapper'>
                                                <b>Valor</b>
                                                <span>{formatPrice(expense.value)}</span>
                                            </div>
                                            <div className='report-wrapper'>
                                                <b>Data</b>
                                                <span>{expense.date.day} / {expense.date.month}</span>
                                            </div>
                                            <div className='report-wrapper'>
                                                <b data-id={expense._id} style={{ color: "crimson" }} onClick={excludeExpense}>Apagar</b>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                    }
                </div>
                : <div className="reports-body" id='reports-body'><span>Nenhuma saída pendente à ser listada.</span></div>
                }
                
            </Container>
            <Footer/>
        </React.Fragment>
    )
}

export default ManageExpense;