import axios from 'axios';

export interface IDate {
    day: string,
    month: string,
    year: string
}

export interface IExpenseData {
    churchId: string,
    description: string,
    value: number,
    date: IDate,
    week: string,
    category: string
}

export async function createExpense(props: IExpenseData) {
    const { churchId, description, value, date, category, week } = props;

    const response = await axios.post(
        `${process.env.REACT_APP_APIPATH}/expenses/create`,
        {
            churchId,
            description,
            value,
            date,
            week,
            category
        },
        {
            Headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
        }
    );

    return response.data;
}