import axios from 'axios';

export async function loadInitialReports() {
    const response = await axios.get(
        `${process.env.REACT_APP_APIPATH}/form/getAllForms`,
        {
            Headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
        }
    );
    return response.data;
}