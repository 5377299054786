import ReactEmail, { Box, Email, Item, Image, Span, A } from "react-html-email";

ReactEmail.configStyleValidator({
    plataforms: [ 'gmail' ]
});

interface IEmailProps {
    name: string;
    recoverUrl: string;
}

const buttonStyle = {
    textDecoration: "none",
    padding: "5px",
    border: "1px solid",
    borderRadius: "6px",
}

const ForgotPasswordMail = (props: IEmailProps) => (
    <Box align="center">
        <Email align="center">
            <Item style={{ height: 45 }}/>
            <Item>
                <Image src={ window.location.origin + "/Logo-SIGT.png" } style={{ margin: '0 auto' }} width={400}/>
            </Item>
            <Item style={{ height: 20 }}/>
            <Item align="center">
                <Box>
                    <Item style={{ height: 20 }}/>

                    <Item align="center">
                        <Span fontSize={30} fontWeight='bold'>Olá {props.name}!</Span>
                    </Item>
                    <Item style={{ height: 25 }}/>

                    <Item align="center">
                        <Span fontSize={15}>
                            Vimos que você está com problemas para acessar o Portal Tranparência da Primeira Igreja Batista... Mas não se preocupa, estamos aqui para ajudar!
                        </Span>
                    </Item>
                    <Item style={{ height: 25 }}/>

                    <Item align="center">
                        <A href={props.recoverUrl} style={buttonStyle}>Criar nova senha</A>
                    </Item>
                    <Item style={{ height: 50 }}/>

                    <Item align="center">
                        <Span fontSize={10}>
                            (Se esta solicitação não foi gerada por você, favor desconsiderar este e-mail)
                        </Span>
                    </Item>
                </Box>
            </Item>
        </Email>
    </Box>
)

export { ForgotPasswordMail };