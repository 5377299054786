import axios from 'axios';

export interface IEmailData {
    email: string;
    subject?: string;
    text?: string;
    html?: string;
}

export async function sendEmail(props: IEmailData) {
    const { email, subject, text, html } = props;

    const response = await axios.post(
        `${process.env.REACT_APP_APIPATH}/user/sendEmail`,
        {
            email: email,
            subject: subject,
            text: text,
            html: html
        }
    )

    return response.data;
}