import '../style/footer.scss'

const Footer = () => {
    return (
        <footer className='footer-element'>
            <span>Todos os direitos reservados © 2022 Primeira Igreja Batista em Santana do São Francisco</span>
            <span>Desenvolvido e mantido por <a href='https://gustavopsantana.dev.br' target="_blank" rel="noreferrer">Gustavo P. Santana</a></span>
        </footer>
    )
}

export default Footer;