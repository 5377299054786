import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

// Main Pages
import Home from "./pages/Home";
import Reports from "./pages/Reports";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";

// Internal Pages
import InternalReports from "./pages/InternalReports";
import CreateWeekReport from "./pages/CreateWeekReport";
import GenerateMonthReport from "./pages/GenerateMonthReport";
import ShowWeekReport from "./pages/ShowWeekReport";
import ShowMonthReport from "./pages/ShowMonthReport";
import RemoveReport from "./pages/RemoveReport";
import ShowYearData from "./pages/ShowYearData";
import ManagePayments from "./pages/ManagePayments";
import ManageExpenses from "./pages/ManageExpenses";
import CreateExpense from "./pages/CreateExpense";
import CreateEntry from "./pages/CreateEntry";
import ManageEntries from "./pages/ManageEntries";
import ShowWeekEntry from "./pages/ShowWeekEntry";

// User Pages
import ManageUsers from "./pages/ManageUsers";
import CreateNewUser from "./pages/CreateNewUser";

const MyRoutes = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/login" element={<Login/>}/>
                <Route path="/esqueci-minha-senha" element={<ForgotPassword/>}/>
                <Route path="/reset-password" element={<ResetPassword/>}/>
                <Route path="/relatorios" element={<Reports/>}/>

                <Route path="/interno/relatorios" element={<InternalReports/>}/>
                <Route path="/interno/relatorios/semanal/novo-relatorio" element={<CreateWeekReport/>}/>
                <Route path="/interno/relatorios/mensal/novo-relatorio" element={<GenerateMonthReport/>}/>
                <Route path="/interno/relatorios/remover-relatorio" element={<RemoveReport/>}/>

                <Route path="/interno/saidas" element={<ManageExpenses/>}/>
                <Route path="/interno/saidas/nova-saida" element={<CreateExpense/>}/>

                <Route path="/interno/entradas" element={<ManageEntries/>}/>
                <Route path="/interno/entradas/nova-entrada" element={<CreateEntry/>}/>

                <Route path="/relatorios/semanal/:formId" element={<ShowWeekReport/>}/>
                <Route path="/relatorios/mensal/:formId" element={<ShowMonthReport/>}/>
                <Route path="/relatorios/entradas/:entryId" element={<ShowWeekEntry/>}/>

                <Route path="/interno/relatorios/acompanhamento-anual/:church/:year"element={<ShowYearData/>}/>

                <Route path="/interno/administracao/gerenciar-usuarios" element={<ManageUsers/>}/>
                <Route path="/interno/administracao/novo-usuario" element={<CreateNewUser/>} />

                <Route path="/interno/pagamentos" element={<ManagePayments/>}/>
            </Routes>
        </Router>
    )
}

export default MyRoutes;