import axios from 'axios';
import { IDate } from '../../expenses/createExpense';

export interface ISearchData {
    formId: string;
}

export interface IWeekData {
    weekNumber: number;
    monthId: number;
    year: number;
}

export interface IExpenseData {
    description: string;
    value: number;
    date: IDate;
    category: string;
}

export interface ICreatorData {
    name: "string",
    email: "string",
    role: "string",
    churchId: "string",
    isActive: "boolean"
}

export interface IReportData {
    _id: "string",
    formId: "string",
    weekData: IWeekData,
    churchId: "string",
    lastBalance: "number",
    creatorData: ICreatorData,
    expenses: Array<Array<IExpenseData>>,
    tenths: "number",
    offers: "number",
    totalEntries: "number",
    totalExpenses: "number",
    balance: "number",
    isActive: "boolean",
    createdAt: "string",
    updatedAt: "string",
    __v: "number"
}

export async function loadWeekReport(props: ISearchData) {
    const { formId } = props;

    const response = await axios.post(
        `${process.env.REACT_APP_APIPATH}/form/week/load`,
        {
            formId: formId,
        },
        {
            Headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
        }
    );

    return response.data;
}