import "../style/header.scss";
import { ReactComponent as Logo } from '../assets/img/IBVC_Horizontal.svg';

import { getCookies, doLogout } from '../controllers/user/authenticate';

function LoginButton() {
    return (
        <a href="/login" className="link-login">
            <span>Log In</span>
        </a>
    );
}
  
  function LogoutButton() {
    return (
      <button className="button-logout" onClick={doLogout}>
        <span>Sair</span>
      </button>
    );
}

const Header = () => {

    var token = getCookies('sessionToken');

    var buttonElement;
    if (token) {
        buttonElement = <LogoutButton/>
    } else {
        buttonElement = <LoginButton/>
    }

    return (
        <header className="header-element">
            <div className="header-container">
                <a href="/">
                    <Logo className="header-logo"/>
                </a>
                <nav className="header-menu">
                    <a href="/relatorios" className="link-reports">
                        <span>Relatórios</span>
                    </a>
                    { buttonElement }
                </nav>
            </div>
        </header>
    )
};

export default Header;