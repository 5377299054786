import axios from 'axios';

export interface IUserData {    
    _id: string,
    name: string,
    email: string,
    password: string,
    role: string,
    churchId: string,
    isActive: boolean,
    createdAt: string,
    updatedAt: string
}

export async function getUsers() {
    const response = await axios.get(
        `${process.env.REACT_APP_APIPATH}/debug/user/getUsers`,
        {
            Headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
        }
    );
    return response.data;
}