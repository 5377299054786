import React from "react";
import iconMenu from '../assets/img/menu-white.svg';
import { getCookies } from "../controllers/user/authenticate";

import "../style/asideMenu.scss";

interface AsideMenuProps {
    isOpen: boolean;
    toggleMenu: () => void;
}

const AsideMenu = (props:AsideMenuProps) => {

    const AdminOptions = () => {
        const userRole = getCookies('userRole');
    
        if (userRole === 'administrador') {
            return (
                <React.Fragment>
                    <li className="aside-menu-item">
                        <a href="/interno/administracao/gerenciar-usuarios" className="aside-menu-link">
                            <b>Gerenciar Usuários</b>
                        </a>
                    </li>
                </React.Fragment>
            )
        } else {
            return null
        }
    }
    
    return (
        <React.Fragment>
            <div className={props.isOpen ? "aside-menu-container open" : "aside-menu-container"}>
                <div className="aside-menu-content">
                    <div className="aside-menu-content-header">
                        <button className="menu-button" onClick={props.toggleMenu}>
                            <img src={iconMenu} width="24px" height="24px" alt="menu-icon" />
                        </button>
                        <h2 className="logo-text">PIB Transparência</h2>
                    </div>
                    <div className="aside-menu-content-body">
                        <ul className="aside-menu-list">
                            <li className="aside-menu-item">
                                <a href="/interno/relatorios" className="aside-menu-link">
                                    <b>Relatórios</b>
                                </a>
                            </li>
                            <li className="aside-menu-item">
                                <a href="/interno/relatorios/semanal/novo-relatorio" className="aside-menu-link">
                                    <b>Novo Relatório Semanal</b>
                                </a>
                            </li>
                            <li className="aside-menu-item">
                                <a href="/interno/relatorios/mensal/novo-relatorio" className="aside-menu-link">
                                    <b>Novo Relatório Mensal</b>
                                </a>
                            </li>

                            <li className="aside-menu-item remove">
                                <a href="/interno/relatorios/remover-relatorio" className="aside-menu-link">
                                    <b>Remover Relatórios</b>
                                </a>
                            </li>

                            <li className="aside-menu-item">
                                <a href="/interno/entradas" className="aside-menu-link">
                                    <b>Gerenciar Entradas</b>
                                </a>
                            </li>

                            <li className="aside-menu-item">
                                <a href="/interno/saidas" className="aside-menu-link">
                                    <b>Gerenciar Saídas</b>
                                </a>
                            </li>

                            <AdminOptions/>

                            <li className="aside-menu-item">
                                <a href="/interno/relatorios/acompanhamento-anual/sede/2021" className="aside-menu-link">
                                    <b>Acompanhamento Anual</b>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default AsideMenu;