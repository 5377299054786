import React from 'react';
import { ReactComponent as QuoteImg } from '../assets/img/Aspas.svg';

import '../style/verse.scss';

const Verse = () => {
    return (
        <div className='verse-container'>
            <div className="verse-element">
                <span>
                    Cada um dê conforme determinou em seu coração, não com pesar ou por obrigação, pois Deus ama quem dá com alegria.
                </span>
                <b>2 Coríntios 9:7</b>
            </div>
            <QuoteImg className="quote-img"/>
        </div>
    )
}

export default Verse;