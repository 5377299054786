import axios from 'axios';

export interface IUpdateData {
    id: string,
    key: string,
    newvalue: string
}

export interface IToggleActivation {
    id: string
}

export async function updateData(props: IUpdateData) {
    const { id, key, newvalue } = props;

    const response = await axios.put(
        `${process.env.REACT_APP_APIPATH}/user/update`,
        {
            id,
            key,
            newvalue
        },
        {
            Headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
        }
    );

    return response.data;
}

export async function activateUser(props: IToggleActivation) {
    const { id } = props;

    const response = await axios.put(
        `${process.env.REACT_APP_APIPATH}/user/activate`,
        {
            id
        },
        {
            Headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
        }
    );

    return response.data;
}

export async function deactivateUser(props: IToggleActivation) {
    const { id } = props;

    const response = await axios.put(
        `${process.env.REACT_APP_APIPATH}/user/deactivate`,
        {
            id
        },
        {
            Headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
        }
    );

    return response.data;
}