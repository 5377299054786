import React from 'react';
import Header from '../components/Header';
import Container from '../components/Container'
import Footer from '../components/Footer';

import MainTitle from '../components/MainTitle';
import SubTitle from '../components/SubTitle';
import Verse from '../components/Verse';
import ButtonReports from '../components/ButtonReports';

const Home = () => {
    return (
      <React.Fragment>
        <Header/>
        <Container style={{ backgroundColor: '#F9F9F9' }}>
          <MainTitle>
            Sistema Integrado de
            <br/>
            Gestão e Transparência
          </MainTitle>
          <SubTitle>
            Entenda onde sua doação está sendo aplicada
          </SubTitle>
          <Verse/>
          <ButtonReports/>
        </Container>
        <Footer/>
      </React.Fragment>
    )
}

export default Home;