import axios from 'axios';

export interface IRemoveData {
    id: string;
}

export async function removeEntry(props: IRemoveData){
    const { id } = props;

    const response = await axios.post(
        `${process.env.REACT_APP_APIPATH}/entry/remove`,
        {
            id: id,
        },
        {
            Headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
        }
    );

    return response.data;
}