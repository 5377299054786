import axios from 'axios';
import Cookies from 'universal-cookie';

export interface IAuthenticateData {
    email: string;
    password: string;
}

export interface ICookieData {
    name: string;
    value: string;
}

export interface IUserData {
    UID: string;
}

export async function authenticate(props:IAuthenticateData) {
    const { email, password } = props;

    const response = await axios.post(
        `${process.env.REACT_APP_APIPATH}/user/login`,
        {
            email: email,
            password: password
        },
        {
            Headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
        }
    );

    return response.data;
}

export async function getUserData(props:IUserData) {
    const { UID } = props;

    const response = await axios.post(
        `${process.env.REACT_APP_APIPATH}/user/find`,
        {
            id: UID
        },
        {
            Headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
        }
    );

    return response.data;
}

export function setCookies(props:ICookieData) {
    const cookies = new Cookies();
    cookies.set(props.name, props.value);
}

export function getCookies(props:string) {
    const cookies = new Cookies();
    return cookies.get(props);
}

export function removeCookies(props:string) {
    const cookies = new Cookies();
    cookies.remove(props);
}

export function doLogout() {
    removeCookies('sessionToken')
    removeCookies('UID');
    removeCookies('userRole');
    removeCookies('userChurch');
    window.location.href = '/login';
}