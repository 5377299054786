import React from 'react';

import '../style/subTitle.scss';

export interface SubTitleProps {
    children: React.ReactNode;
}

const SubTitle = (props:SubTitleProps) => {
    return (
        <h2 className='subtitle-element'>
            {props.children}
        </h2>
    )
}

export default SubTitle;