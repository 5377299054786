interface monthData {
    monthId: number,
	year: number
}

interface weekData {
    weekNumber: number,
	monthId: number,
	year: number
}

export interface IReportProps {
    formId: string;
    churchId: string;
    monthData?: monthData;
    weekData?: weekData;
    lastBalance: number;
    tenths: number;
    offers: number;
    totalEntries: number;
    totalExpenses: number;
    balance: number;
}

function formatPrice(price: number): string {
    return price.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
}

function capitalize(string: string): string {
    return string && string[0].toUpperCase() + string.slice(1);
}

const months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];

const Report = (props: IReportProps) => {

    const { churchId, formId, monthData, weekData, lastBalance, tenths, offers, totalEntries, totalExpenses, balance } = props;

    var churchName = `PIB ${capitalize(churchId)}`;

    var title;

    if (monthData) {
        title = `${churchName} - Mensal - ${months[monthData.monthId]}/${monthData.year}`
    } else if (weekData) {
        title = `${churchName} - ${weekData.weekNumber}ª Semana - ${months[weekData.monthId]}/${weekData.year}`
    }

    function goToReport() {
        console.log(window.location.pathname);
        if (window.location.pathname === '/interno/relatorios' || window.location.pathname === '/interno/relatorios/'){
            if (weekData) {
                let reportUrl = window.location.protocol + "//" + window.location.host + '/relatorios/semanal/' + formId;
                let win = window.open(reportUrl, '_blank');
                win?.focus()
            } else {
                let reportUrl = window.location.protocol + "//" + window.location.host + '/relatorios/mensal/' + formId;
                let win = window.open(reportUrl, '_blank');
                win?.focus()
            }
        }
    }

    return (
        <div className='report-element' onClick={goToReport}>
            <h3>{title}</h3>
            <div className="report-body">
                <div className='report-wrapper'>
                    <p>
                        <b>Saldo Anterior: </b>
                        <span>{formatPrice(lastBalance)}</span>
                    </p>
                    <p>
                        <b>Dízimos: </b>
                        <span>{formatPrice(tenths)}</span>
                    </p>
                    <p>
                        <b>Ofertas: </b>
                        <span>{formatPrice(offers)}</span>
                    </p>
                    <p>
                        <b>Total de Entradas: </b>
                        <span>{formatPrice(totalEntries)}</span>
                    </p>
                </div>
                <div className='report-wrapper'>
                    <b>Saídas Gerais {monthData ? "do Mês" : "da Semana"}</b>
                    <span>{formatPrice(totalExpenses)}</span>
                </div>
                <div className='report-wrapper'>
                    <b>Saldo Parcial {monthData ? "do Mês" : "da Semana"}</b>
                    <span>{formatPrice(balance)}</span>
                </div>
            </div>
        </div>
    )
}

export default Report;