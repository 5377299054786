import React, {useState, useMemo, useRef} from "react";
import { useParams } from "react-router-dom";
import { jsPDF } from 'jspdf';

import { IReportData, ISearchData, loadMonthReport } from "../controllers/reports/month/loadMonthReport";
import { validateUserAgent } from '../controllers/misc/validateUserAgent';

import "../style/showReport.scss";

interface IGlobalExpenses {
    bonuses: number;
    rents: number;
    patrimony: number;
    reform: number;
    coopPlan: number;
    minFund: number;
    watLight: number;
    socialActivity: number;
    internet: number;
    eventuals: number;
}

function capitalize(string: string): string {
    return string && string[0].toUpperCase() + string.slice(1);
}

const ShowMonthReport = () => {
    const pdfRef = useRef(null);
    var { formId } = useParams();
    var [reports, setReports] = useState([] as Array<IReportData>);
    const month = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
    const fullMonth = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

    function getReport(props: ISearchData) {
        loadMonthReport(props)
            .then( (data: IReportData) => setReports([data]))
            .catch( err => console.log(err) )
    }

    var globalExpenses = {} as IGlobalExpenses;
    globalExpenses.bonuses = 0;
    globalExpenses.rents = 0;
    globalExpenses.patrimony = 0;
    globalExpenses.reform = 0;
    globalExpenses.coopPlan = 0;
    globalExpenses.minFund = 0;
    globalExpenses.watLight = 0;
    globalExpenses.socialActivity = 0;
    globalExpenses.eventuals = 0;
    globalExpenses.internet = 0;

    reports.map((report: IReportData) => {

        report.expenses.map((expensesArr) => {
            if (expensesArr[0].category === "GRATIFICAÇÕES") {
                globalExpenses.bonuses = globalExpenses.bonuses + expensesArr[0].value;
            } else if (expensesArr[0].category === "ALUGUÉIS") {
                globalExpenses.rents = globalExpenses.rents + expensesArr[0].value;
            } else if (expensesArr[0].category === "PATRIMÔNIO") {
                globalExpenses.patrimony = globalExpenses.patrimony + expensesArr[0].value;
            } else if (expensesArr[0].category === "REFORMA") {
                globalExpenses.reform = globalExpenses.reform + expensesArr[0].value;
            } else if (expensesArr[0].category === "PLANO COOPERATIVO") {
                globalExpenses.coopPlan = globalExpenses.coopPlan + expensesArr[0].value;
            } else if (expensesArr[0].category === "FUNDO MINISTERIAL") {
                globalExpenses.minFund = globalExpenses.minFund + expensesArr[0].value;
            } else if (expensesArr[0].category === "ÁGUA E LUZ") {
                globalExpenses.watLight = globalExpenses.watLight + expensesArr[0].value;
            } else if (expensesArr[0].category === "AÇÃO SOCIAL") {
                globalExpenses.socialActivity = globalExpenses.socialActivity + expensesArr[0].value;
            } else if (expensesArr[0].category === "INTERNET") {
                globalExpenses.internet = globalExpenses.internet + expensesArr[0].value;
            } else if (expensesArr[0].category === "EVENTUAIS") {
                globalExpenses.eventuals = globalExpenses.eventuals + expensesArr[0].value;
            }
        })
    })

    function savePDF() {

        if (validateUserAgent() === 'iOS') {
            document.title = `Relatório Finançeiro ${fullMonth[reports[0].monthData.monthId]} - ${reports[0].monthData.year} - PIB ${capitalize(reports[0].churchId)}`;
            window.print();
        } else {
            var doc = new jsPDF('portrait', 'pt', 'a4');
            const content = pdfRef.current as unknown;
            
            doc.html(
                content as HTMLDivElement, 
                {
                    margin: 40,
                    html2canvas: { scale: 0.8 },
                    x: 10,
                    y: 10,
                    callback: function (doc) {
                        doc.save(`Relatório Finançeiro ${fullMonth[reports[0].monthData.monthId]} - ${reports[0].monthData.year} - PIB ${capitalize(reports[0].churchId)}`);
                        window.close();
                    }
                }
            );
        }
        
    }

    function autoPrintReport() {
        setTimeout(savePDF, 500);
    }

    var searchData = { formId: formId } as ISearchData;

    // eslint-disable-next-line
    var searchReport = useMemo(() => getReport(searchData), reports)
    document.addEventListener('DOMContentLoaded', () => searchReport);

 
    
    return (
        <React.Fragment>
            {
                reports.map((report: IReportData) => {

                    return (
                        <React.Fragment>
                            <div className="report-container month-container" ref={pdfRef}>
                                <div className="report-title">
                                    <h1 key={report._id} style={{ fontFamily: "Arial" }}>Relatório&nbsp;&nbsp;Financeiro&nbsp;&nbsp;{month[report.monthData.monthId]}/{report.monthData.year} - PIB {capitalize(report.churchId)}</h1>
                                </div>
                                <div className="report-body">
                                    <div className="report-wrapper">
                                        <div className="row month">
                                            <span className="report-label" style={{ fontFamily: "Helvetica" }}>Saldo Anterior ({month[report.monthData.monthId - 1] || 'Dez'}/{report.monthData.year}): {report.lastBalance.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
                                        </div>
                                        <div className="row month">
                                            <span className="report-label" style={{ fontFamily: "Helvetica" }}>Dízimos: {parseFloat(report.tenths).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
                                        </div>
                                        <div className="row month">
                                            <span className="report-label" style={{ fontFamily: "Helvetica" }}>Ofertas: {parseFloat(report.offers).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
                                        </div>
                                        <div className="row total-entries">
                                            <span className="report-label" style={{ fontFamily: "Helvetica" }}>Todas as Entradas: {parseFloat(report.totalEntries).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
                                        </div>
                                        <div className="row title">
                                            <span className="report-label" style={{ fontFamily: "Helvetica", fontWeight: "bold" }}>Gastos por Categoria</span>
                                        </div>
                                        <div className="row month">
                                            <table className="main-table reforma">
                                                <thead>
                                                    <tr>
                                                        <td style={{ fontFamily: "Helvetica" }}>Categoria</td>
                                                        <td style={{ fontFamily: "Helvetica" }}>Saída Total</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ fontFamily: "Helvetica" }}>Gratificações</td>
                                                        <td style={{ fontFamily: "Helvetica" }}>{globalExpenses.bonuses.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontFamily: "Helvetica" }}>Aluguéis</td>
                                                        <td style={{ fontFamily: "Helvetica" }}>{globalExpenses.rents.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontFamily: "Helvetica" }}>Patrimônio</td>
                                                        <td style={{ fontFamily: "Helvetica" }}>{globalExpenses.patrimony.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontFamily: "Helvetica" }}>Reforma</td>
                                                        <td style={{ fontFamily: "Helvetica" }}>{globalExpenses.reform.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontFamily: "Helvetica" }}>Plano Cooperativo</td>
                                                        <td style={{ fontFamily: "Helvetica" }}>{globalExpenses.coopPlan.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontFamily: "Helvetica" }}>Fundo Ministerial</td>
                                                        <td style={{ fontFamily: "Helvetica" }}>{globalExpenses.minFund.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontFamily: "Helvetica" }}>Água e Luz</td>
                                                        <td style={{ fontFamily: "Helvetica" }}>{globalExpenses.watLight.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontFamily: "Helvetica" }}>Ação Social</td>
                                                        <td style={{ fontFamily: "Helvetica" }}>{globalExpenses.socialActivity.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontFamily: "Helvetica" }}>Internet</td>
                                                        <td style={{ fontFamily: "Helvetica" }}>{globalExpenses.internet.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontFamily: "Helvetica" }}>Eventuais</td>
                                                        <td style={{ fontFamily: "Helvetica" }}>{globalExpenses.eventuals.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <br />
                                        <div className="row month">
                                            <span className="report-label" style={{ fontFamily: "Helvetica" }}>Todas as Saídas: {parseFloat(report.totalExpenses).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
                                        </div>
                                        <div className="row month">
                                            <span className="report-label" style={{ fontFamily: "Helvetica" }}>Saldo Atual: {parseFloat(report.balance).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
                                        </div>
                                        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                                        <div className="row title">
                                            <span className="report-label" style={{ fontFamily: "Helvetica", fontWeight: "bold" }}>Saídas Gerais</span>
                                        </div>
                                        <div className="row month">
                                            <table className="main-table">
                                                <thead>
                                                    <tr>
                                                        <td style={{ fontFamily: "Helvetica" }}>Descrição da Saída</td>
                                                        <td style={{ fontFamily: "Helvetica" }}>Valor da Saída</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        report.expenses.map(( expenseArr ) => {
                                                            if (expenseArr[0].category !== 'REFORMA') {
                                                                return (
                                                                    <tr>
                                                                        <td style={{ fontFamily: "Helvetica" }}>{expenseArr[0].description}</td>
                                                                        <td style={{ fontFamily: "Helvetica" }}>{expenseArr[0].value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                                    </tr>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        { globalExpenses.reform ?
                                                <React.Fragment>
                                                    <div className="row title">
                                                        <span className="report-label" style={{ fontFamily: "Helvetica", fontWeight: "bold" }}>Saídas&nbsp;&nbsp;da&nbsp;&nbsp;Reforma</span>
                                                    </div>
                                                    <div className="row month">
                                                        <table className="main-table reforma">
                                                            <thead>
                                                                <tr>
                                                                    <td style={{ fontFamily: "Helvetica" }}>Descrição da Saída</td>
                                                                    <td style={{ fontFamily: "Helvetica" }}>Valor da Saída</td>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    report.expenses.map(( expenseArr ) => {
                                                                        if (expenseArr[0].category === 'REFORMA') {
                                                                            return (
                                                                                <tr>
                                                                                    <td style={{ fontFamily: "Helvetica" }}>{expenseArr[0].description}</td>
                                                                                    <td style={{ fontFamily: "Helvetica" }}>{expenseArr[0].value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                                                </tr>
                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </React.Fragment> : ""
                                        }
                                    </div>

                                </div>
                            </div>
                        </React.Fragment>
                    )
                }, autoPrintReport())
            }
        </React.Fragment>
    )
}

export default ShowMonthReport;