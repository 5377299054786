import React, { useState } from "react";

import InternalHeader from '../components/InternalHeader';
import Container from '../components/Container';
import Footer from '../components/Footer';
import { validateUserAgent } from '../controllers/misc/validateUserAgent';
//import LoadingPanel from '../components/LoadingPanel';

import { IDecimistData, IEntryData, createEntry } from '../controllers/entries/createEntry';

import '../style/reports.scss';
import "../style/manageUsers.scss";

function getWeek(date: Date) {
    var date = new Date(date.getTime());
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    var week1 = new Date(date.getFullYear(), 0, 4);
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
}

const CreateEntry = () => {
    const [churchId, setChurchId] = useState('sede');
    const [week, setWeek] = useState(`${new Date().getFullYear()}-W${getWeek(new Date())}`);
    const [offers, setOffers] = useState(0);
    const [decimists, setDecimists] = useState([] as Array<IDecimistData>);
    const [name, setName] = useState("");
    const [tenth, setTenth] = useState(0);

    const handleChurchIdChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setChurchId(event.target.value);
    }

    function handleWeekChange2(event: React.ChangeEvent<HTMLInputElement>) {
        setWeek(event.target.value);
    }

    function handleWeekChange(event: React.ChangeEvent<HTMLSelectElement>){
        setWeek(event.target.value);
    }

    function handleOffersChange(event: React.ChangeEvent<HTMLInputElement>) {
        setOffers(parseFloat(event.target.value));
    }

    function handleNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        setName(event.target.value);
    }

    function handleTenthChange(event: React.ChangeEvent<HTMLInputElement>) {
        setTenth(parseFloat(event.target.value));
    }


    const WeekSelector = () => {

        const weeks = [] as Array<string>;

        for (var i = 0; i < 7; i++) {
            var atualWeek = getWeek(new Date());
            var weekString = `${new Date().getFullYear()}-W${atualWeek - i}`;
            weeks.push(weekString);
        }

        if (validateUserAgent() === 'iOS' || validateUserAgent() === 'Mac') {
            return (
                <select name="week" id="week" value={week} onChange={handleWeekChange}>
                    {
                        weeks.map((week: string) => {
                            var weekNumber = week.split('W')[1]; 
                            return (
                                <option value={week}>Semana {weekNumber}</option>
                            )
                        })
                    }
                </select>
            )
        } else {
            return(
                <input type="week" name="week" id="week" value={week} onChange={handleWeekChange2}/>
            )
        }
    }

    function addDecimist() {
        var decimist = {} as IDecimistData;
        const thentInput = document.getElementById('thent') as HTMLInputElement;

        decimist.name = name;
        decimist.tenth = tenth;

        decimists.push(decimist);

        console.log(decimists)

        var rowContent = document.createElement('tr') as HTMLTableRowElement;

        rowContent.innerHTML = `<td>${decimist.name}</td><td className="last">R$ ${decimist.tenth}</td>`

        document.getElementById('decimists-table')
            ?.getElementsByTagName('tbody')[0].appendChild(rowContent)

        setName("");
        setTenth(0);
    }

    function registerEntries() {
        var entry = {} as IEntryData;

        entry.churchId = churchId;
        entry.week = week;
        entry.offers = offers;
        entry.decimists = decimists;

        createEntry(entry)
            .then(sucess => {
                window.location.href = window.location.protocol + "//" + window.location.host + '/interno/entradas';
            })
    }


    return (
        <React.Fragment>
                <InternalHeader/>
                <Container style={{ justifyContent: 'flex-start', backgroundColor: '#F9F9F9' }}>
                    <div className='user-header'>
                        <div className="title-container">
                            <h2>Nova Entrada</h2>
                        </div>
                    </div>
                    <div className="user-body" id='user-body'>
                        <div className="user-wrapper">
                            <div className="new-user-wrapper">
                                <div>
                                    <label htmlFor="churchId">IGREJA</label>
                                    <select name="churchId" id="churchId" onChange={handleChurchIdChange} value={churchId}>
                                        <option value="sede">PIB Sede</option>
                                        <option value="cohab">PIB Cohab</option>
                                        <option value="saude">PIB Saúde</option>
                                    </select>
                                </div>
                            </div>
                            <div className="new-user-wrapper">
                                <div>
                                    <label htmlFor="week">SEMANA</label>
                                    <WeekSelector/>
                                </div>
                            </div>
                            <div className="new-user-wrapper">
                                <div>
                                    <label htmlFor="offers">OFERTAS</label>
                                    <input type="number" inputMode="decimal" name="offers" id="offers" placeholder='800.00' onChange={handleOffersChange}/>
                                </div>
                            </div>
                        </div>
                        
                        <div className='users-container'>
                            <table id="decimists-table" className='users-table'>
                                <thead>
                                    <th>Nome</th>
                                    <th>Dízimo</th>
                                </thead>
                                <tbody>
                                
                                </tbody>
                            </table>
                        </div>

                        <div className="new-decimist-wrapper">
                            <div>
                                <div>
                                    <div>
                                        <label htmlFor="decimists">NOME</label>
                                        <input style={{ maxWidth: '200px' }} type="text" name="decimists" id="decimists" placeholder='Nome do Dizimista' onChange={handleNameChange} value={name}/>
                                    </div>
                                    <div>
                                        <label htmlFor="tenth">DÍZIMO</label>
                                        <input type="number" inputMode="decimal" name="tenth" id="tenth" placeholder='80.00' onChange={handleTenthChange}/>
                                    </div>

                                    <div className="new-user-wrapper save">
                                        <button onClick={() => addDecimist()}>+</button>
                                    </div>
                                </div>
                            </div>
                        </div> 

                        <div className="new-user-wrapper save">
                            <button onClick={registerEntries}>SALVAR</button>
                        </div>               
                    </div>
                </Container>
                <Footer/>
        </React.Fragment>
    )
}

export default CreateEntry;