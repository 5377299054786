import axios from "axios";

export interface IMonthData {
    monthId: number,
    year: number
}

export interface IReportQuery {
    weekFormIds: Array<String>,
    churchId: string,
    monthData: IMonthData,
    creatorId: string
}

export interface ISearchData {
    monthData: IMonthData,
    churchId: string
}

export async function verifyAlreadyForm(props:ISearchData) {
    const { churchId, monthData } = props;
    const months = ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'];

    const formId = `mensal-${months[monthData.monthId]}${monthData.year}-${churchId}`;

    const response = await axios.post(
        `${process.env.REACT_APP_APIPATH}/form/month/load`,
        {
            formId: formId
        },
        {
            Headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
        }
    );

    if (!response.data.createdAt) {
        return {
            'isCreated': false
        }
    } else {
        return {
            'isCreated': true,
            data: response.data
        }
    }


}

export async function getWeekFromIds(props:ISearchData) {
    const { churchId, monthData } = props;

    const response = await axios.post(
        `${process.env.REACT_APP_APIPATH}/form/week/search`,
        {
            churchId: churchId,
            monthId: monthData.monthId,
            year: monthData.year
        },
        {
            Headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
        }
    );

    return response.data;
}

export async function genrateMonthReport(props:IReportQuery) {
    const { churchId, creatorId, monthData, weekFormIds } = props;

    const response = await axios.post(
        `${process.env.REACT_APP_APIPATH}/form/month/create`,
        {
            weekFormIds: weekFormIds,
            churchId: churchId,
            monthData: monthData,
            creatorId: creatorId
        },
        {
            Headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
        }
    );

    return response.data;
}