import React, { useState } from 'react';
import { createUser, IUserData } from '../controllers/user/createUser';

import InternalHeader from '../components/InternalHeader';
import Container from '../components/Container'
import Footer from '../components/Footer';

import '../style/createNewUser.scss';


const CreateNewUser =  () => {
    var [name, setName] = useState("");
    var [email, setEmail] = useState("");
    var [role, setRole] = useState("member");
    var [churchId, setChurchId] = useState("sede");
    var pwdfill = "pib@123PIB";

    function handleNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        setName(event.target.value);
    }

    function handleEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
        setEmail(event.target.value);
    }

    function handleRoleChange(event: React.ChangeEvent<HTMLSelectElement>) {
        setRole(event.target.value);
    }

    function handleChurchIdChange(event: React.ChangeEvent<HTMLSelectElement>) {
        setChurchId(event.target.value);
    }

    function doCreateUser() {
        var userData = {} as IUserData;
        var messageElement = document.querySelector('.message-wrapper span') as HTMLSpanElement;

        userData.name = name;
        userData.email = email;
        userData.role = role;
        userData.churchId = churchId;
        userData.password = pwdfill;
        userData.confirmPassword = pwdfill;

        createUser(userData)
            .then(response => {
                if (response.errorId) {
                    if (response.errorId === "register_02"){
                        // Email Format Error
                        messageElement.textContent = "Formato de e-mail inválido"
                    } else if (response.errorId === "register_03") {
                        // Password Format Error
                        messageElement.textContent = "Formato de senha inválido"
                    } else if (response.errorId === "register_04") {
                        // Email Already Registered Error
                        messageElement.textContent = "Email já associado a um cadastro"
                    }

                    messageElement.style.display = 'flex'
                    setTimeout(() => {
                        messageElement.style.display = 'none'
                    }, 5000)
                } else {
                    window.location.href = window.location.protocol + "//" + window.location.host + '/interno/administracao/gerenciar-usuarios';
                }
            })
    }

    return (
        <React.Fragment>
            <InternalHeader/>
            <Container style={{ justifyContent: 'flex-start', backgroundColor: '#F9F9F9' }}>
                <div className='user-header'>
                    <div className="title-container">
                        <a href='/interno/administracao/gerenciar-usuarios'>🔙</a>
                        <h2>Criar Usuário</h2>
                    </div>
                </div>
                <div className="user-body" id='user-body'>
                    <div className="user-wrapper">
                        <div className="new-user-wrapper">
                            <div>
                                <label htmlFor="name">NOME</label>
                                <input type="text" name="name" id="name" placeholder='Ex.: João Da Silva' onChange={handleNameChange}/>
                            </div>
                        </div>
                        <div className="new-user-wrapper">
                            <div>
                                <label htmlFor="email">EMAIL</label>
                                <input type="email" name="email" id="email" placeholder="exemplo@pibvidaemcristo.com.br" onChange={handleEmailChange}/>
                            </div>
                        </div>
                        <div className="new-user-wrapper">
                            <div>
                                <label htmlFor="role">CARGO</label>
                                <select name="role" id="role" onChange={handleRoleChange} value={role}>
                                    <option value="membro">Membro</option>
                                    <option value="administrador">Administrador</option>
                                    <option value="tesoureiro">Tesoureiro</option>
                                    <option value="pastor">Pastor</option>
                                </select>
                            </div>
                        </div>
                        <div className="new-user-wrapper">
                            <div>
                                <label htmlFor="churchId">IGREJA</label>
                                <select name="churchId" id="churchId" onChange={handleChurchIdChange} value={churchId}>
                                    <option value="sede">PIB Sede</option>
                                    <option value="cohab">PIB Cohab</option>
                                    <option value="saude">PIB Saúde</option>
                                </select>
                            </div>
                        </div>
                        <div className="new-user-wrapper" style={{ justifyContent: 'center' }}>
                            <div className="message-wrapper">
                                <span>Email já associado a um cadastro.</span>
                            </div>
                        </div>
                        <div className="new-user-wrapper save">
                            <button onClick={doCreateUser}>Cadastrar</button>
                        </div>
                    </div>
                </div>
            </Container>
            <Footer/>
        </React.Fragment>
    )
}

export default CreateNewUser;