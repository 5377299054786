import axios from 'axios';

export interface ISearchData {
    page: number;
}

export async function listBillingRequests(props: ISearchData) {
    const { page } = props;

    const response = await axios.post(
        `${process.env.REACT_APP_APIPATH}/payment/listPaymentRequests`,
        {
            page
        },
        {
            Headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
        }
    )

    return response.data
}