import React from 'react';
import MyRoutes from './routes';
import "./style/global.scss";

function App() {
  return (
    <MyRoutes />
  );
}

export default App;
