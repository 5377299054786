import React, { useState } from 'react';

import InternalHeader from '../components/InternalHeader';
import Container from '../components/Container';
import Footer from '../components/Footer';

import { getCookies } from '../controllers/user/authenticate';
import { IMonthData, IReportQuery, ISearchData, verifyAlreadyForm, getWeekFromIds, genrateMonthReport } from '../controllers/reports/month/generateMonthReport';

import LoadingPanel from '../components/LoadingPanel';

import '../style/reports.scss';

const GenerateMonthReport = () => {

    var [churchId, setChurchId] = useState('sede');
    var [monthId, setMonthId] = useState(0);
    var [year, setYear] = useState(2021);
    const [loading, setLoading] = useState(false);

    function handleChurchIdChange(event: React.ChangeEvent<HTMLSelectElement>) {
        setChurchId(event.target.value);
    }

    function handleMonthIdChange(event: React.ChangeEvent<HTMLSelectElement>) {
        setMonthId(parseInt(event.target.value));
    }

    function handleYearChange(event: React.ChangeEvent<HTMLSelectElement>) {
        setYear(parseInt(event.target.value));
    }

    function generateForm() {
        var searchData = {} as ISearchData;
        var monthData = {} as IMonthData;

        monthData.monthId = monthId;
        monthData.year = year;

        searchData.churchId = churchId;
        searchData.monthData = monthData;

        verifyAlreadyForm(searchData)
            .then(response => {
                if (response.isCreated) {
                    var messageElement = document.querySelector('.message-wrapper span') as HTMLSpanElement;
                    messageElement.style.display = "block";

                    let reportUrl = window.location.protocol + "//" + window.location.host + '/relatorios/mensal/' + response.data.formId;
                    let win = window.open(reportUrl, '_blank');
                    win?.focus();
                } else {
                    getWeekFromIds(searchData)
                        .then(response => {
                            setLoading(true);
                            var reportQuery = {} as IReportQuery;

                            reportQuery.churchId = churchId;
                            reportQuery.creatorId = getCookies('UID');
                            reportQuery.monthData = monthData;
                            reportQuery.weekFormIds = response;

                            genrateMonthReport(reportQuery)
                                .then(response => {
                                    if (response.errorId) {
                                        setLoading(false);
                                        messageElement.style.display = 'flex'
                                        setTimeout(() => {
                                            messageElement.style.display = 'none'
                                        }, 3000)
                                    } else {
                                        let reportUrl = window.location.protocol + "//" + window.location.host + '/relatorios/mensal/' + response.formId;
                                        let win = window.open(reportUrl, '_blank');
                                        win?.focus()
                                        let retutnUrl = window.location.protocol + "//" + window.location.host + '/interno/relatorios';
                                        window.location.href = retutnUrl;
                                    }
                                })
                                .catch(error => {
                                    setLoading(false);
                                    console.log(error)
                                })

                            
                        })
                        .catch(error => console.log(error))
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    return (
        <React.Fragment>
            <LoadingPanel loading={loading}/>
            <InternalHeader/>
            <Container style={{ justifyContent: 'flex-start', backgroundColor: '#F9F9F9' }}>
            <div className='reports-header' style={{ alignSelf: "unset", paddingLeft: "unset" }}>
                    <div className="title-container">
                        <h2>Gerar Formulário Mensal</h2>
                    </div>
                    <div id='search-container'>
                        <div className="search-wrapper">
                            <select name="church" id="church" onChange={handleChurchIdChange}>
                                <option value="sede">PIB Sede</option>
                                <option value="cohab">PIB Cohab</option>
                                <option value="saude">PIB Saúde</option>
                            </select>
                        </div>

                        <div className="search-wrapper">
                            <select name="month" id="month" onChange={handleMonthIdChange}>
                                <option value="0">Janeiro</option>
                                <option value="1">Fevereiro</option>
                                <option value="2">Março</option>
                                <option value="3">Abril</option>
                                <option value="4">Maio</option>
                                <option value="5">Junho</option>
                                <option value="6">Julho</option>
                                <option value="7">Agosto</option>
                                <option value="8">Setembro</option>
                                <option value="9">Outubro</option>
                                <option value="10">Novembro</option>
                                <option value="11">Dezembro</option>
                            </select>
                        </div>

                        <div className="search-wrapper">
                            <select name="year" id="year" onChange={handleYearChange}>
                                <option value="2021">2021</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="reports-body" style={{ alignSelf: "unset", paddingLeft: "unset" }}>
                    <div className="new-report-wrapper" style={{ justifyContent: 'center' }}>
                        <div className="message-wrapper">
                            <span>Formulário mensal já existe. Redirecionando para o relatório...</span>
                        </div>
                    </div>

                    <div className="new-report-wrapper save" style={{ padding: '0' }}>
                        <button onClick={generateForm}>
                            Gerar Relatório
                        </button>
                    </div>
                </div>
            </Container>
            <Footer/>
        </React.Fragment>
    )
}

export default GenerateMonthReport;