import axios from 'axios';
import { IDate } from '../../expenses/createExpense';

export interface IWeekData {
    weekNumber: number;
    monthId: number;
    year: number;
}

export interface IExpenseData {
    _id: string;
    description: string;
    value: number;
    date: IDate;
    category: string;
}

export interface IReportData {
    churchId: string;
    weekData: IWeekData;
    creatorId: string;
    tenths: number;
    offers: number;
    expenses: Array<IExpenseData>;
}

export async function createWeekReport(props: IReportData) {
    const { churchId, weekData, creatorId, tenths, offers, expenses } = props;

    const response = await axios.post(
        `${process.env.REACT_APP_APIPATH}/form/week/create`,
        {
            churchId: churchId,
            weekData: weekData,
            creatorId: creatorId,
            tenths: tenths,
            offers: offers,
            expenses: expenses
        },
        {
            Headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
        }
    );

    return response.data
}